<template>
  <v-form v-if="item" autocomplete="off">
    <v-row>

        <v-col md=12>
          <v-text-field
            id="lawsuitNumberInput"
            name="number"
            label="Nº do processo"
            v-model="item.number"
            hint=""
          ></v-text-field>

          <div v-if="item.attached_numbers != undefined && item.attached_numbers.length > 0">

          <v-text-field
            id="lawsuitNumberInput"
            name="number"
            label="Nº do apenso I"
            v-model="item.attached_numbers[0]"
            hint=""
          ></v-text-field>

          <v-text-field
            id="lawsuitNumberInput"
            name="number"
            label="Nº do apenso II"
            v-model="item.attached_numbers[1]"
            hint=""
          ></v-text-field>

          </div>
          
          <v-text-field
            id="courtInput"
            name="court"
            label="Tribunal"
            v-model="item.court"
            hint="nome completo do tribunal"
          ></v-text-field>

          <v-text-field
            id="cityInput"
            name="city"
            v-model="item.city"
            label="Cidade"
            hint="cidade sede"
           ></v-text-field>

            <v-select
              id="provinceInput"
              name="province"
              v-model="item.province"
              label="UF"
              hint="estado"
              item-text="name"
              item-value="value"
              :items="provinces"
            ></v-select>
          
        </v-col>

    </v-row>

  </v-form>

</template>

<script>
const {EventBus} = require('../../addons/event-bus.js')

export default {
    name: 'LawsuitForm',
    props: ['item', 'legalStatus', 'roles', 'provinces'],
    methods: {
      submit() {
        return EventBus.$emit('lawsuit-submitted')
      }
    },
  }
</script>
