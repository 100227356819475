<template>
  <span>

      <v-card>
        <v-card-title class="headline grey lighten-2">
          Selecione as opções desejadas
        </v-card-title>

        <v-form>
          <v-label>Formato da data</v-label>
          <v-radio-group v-model="options.dateFormat">
            <v-radio
              label="dd/mm/yyyy"
              value="pt-br"
            ></v-radio>
            <v-radio
              label="mm/dd/yyyy"
              value="en-us"
            ></v-radio>
            <!--
            <v-radio
              label="yyyy-mm-dd"
              value="system"
            ></v-radio>
            -->
          </v-radio-group>
          <v-label>Formato dos valores $</v-label>
          <v-radio-group v-model="options.moneyFormat">
            <v-radio
              key="moneyFormat1"
              label="R$ 1.000,99"
              :value="true"
            ></v-radio>
            <v-radio
              key="moneyFormat2"
              label="1000.99"
              :value="false"
            ></v-radio>
          </v-radio-group>
          <v-label>Tipo de relatório</v-label>
          <v-radio-group v-model="options.reportType">
            <v-radio
              key="report1"
              label="Casos"
              value="lawsuits"
            ></v-radio>
            <v-radio
              key="report2"
              label="Custas"
              value="costs"
            ></v-radio>
          <!--
           <v-radio
              key="report3"
              label="Bens penhorados"
              value="properties"
            ></v-radio>
            <v-radio
              key="report4"
              label="Completo"
              value="full"
            ></v-radio>
            -->
           </v-radio-group>
          <v-label>Formato do arquivo</v-label>
          <v-radio-group v-model="options.spreadsheetFormat">
            <v-radio
              :disabled="options.reportType == 'full'"
              label="CSV"
              value="csv"
            ></v-radio>
            <v-radio
              label="Excel 2007 (xlsx)"
              value="xlsx"
            ></v-radio>
            <v-radio
              label="Excel (xls)"
              value="xls"
            ></v-radio>
          </v-radio-group>
        </v-form>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

        <p v-if="downloadLink">
            <a :href="'https://iron.gyramais.com.br/static/uploads/' + downloadLink" target="_blank">
            download link
            <v-icon>mdi-link</v-icon>
          </a>
        </p>
          
        <v-btn
          id="spreadsheetButtonLg"
          color="dark-grey"
          dark
          @click="submit()"
        >
        <v-icon>mdi-spreadsheet</v-icon>
          Gerar
        </v-btn>
    
        </v-card-actions>
      </v-card>
  </span>
</template>
<script>
  import {
    // displayLegalLawsuitSpreadsheet,
    displayFullLawsuitReportSpreadsheet,
    displayInternalLawsuitSpreadsheet,
    displayCostsSpreadsheet,
    displayPropertiesSpreadsheet,
  } from '../spreadsheets.js'

  export default {
    name: 'LawsuitSpreadsheet',
    props: ['items'],
    methods: {
      submit() {
        this.generated = false
        let data = []

        switch (this.options.reportType) {
          case 'lawsuits':
            data = displayInternalLawsuitSpreadsheet(this.items, this.dictionary, this.options.dateFormat, this.options.moneyFormat)
            break
          case 'properties':
            data = displayPropertiesSpreadsheet(this.items, this.dictionary, this.options.dateFormat, this.options.moneyFormat)
            break
          case 'costs':
            data = displayCostsSpreadsheet(this.items, this.dictionary, this.options.dateFormat, this.options.moneyFormat)
            break
          case 'full':
            data = displayFullLawsuitReportSpreadsheet(this.items, this.dictionary, this.options.dateFormat, this.options.moneyFormat)
            break
          default:
            break
        }

        if (data) {
          const spreadsheet = {payload: data, format: this.options.spreadsheetFormat}
          const p1 = this.$store.dispatch('toSpreadsheet', {spreadsheet})
          return p1.then(r => {
            this.$store.commit('setReady')
            this.generated = true
            return r
          })
        }
      }
    },
    computed: {
      dictionary() {
        return this.$store.getters.dictionary
      },
      downloadLink() {
        return this.$store.state.entities.lawsuit.reports.downloadLink
      }
    },
    data: () => {
      return {
        generated: false,
        dialog: false,
        options: {
          reportType: 'lawsuits',
          dateFormat: 'pt-br',
          moneyFormat: true,
          spreadsheetFormat: 'csv',
        }
      }
    }
  }

</script>
