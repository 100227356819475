<template>
    <v-form v-if="item" autocomplete="off">
      <v-row>

        <v-col md=12>

          <div v-if="item.office && item.status === 'sent'">
            <p>Enviado para</p>
            <h4 v-if="offices && offices.length > 0">{{offices.filter(i => {return i.id === item.office})[0].name}}</h4>
          </div>

          <v-select
            id="officeInput"
            name="office"
            label="Escritório"
            v-if="item.status  !== 'sent' || item.office === null"
            v-model="item.office"
            item-text="name"
            item-value="id"
            :items="offices"
            hint="depois de enviado, não pode ser alterado"
          ></v-select>

          <br>
          <v-text-field
            id="feeValueTypeInput"
            name="feeValue"
            v-model="item.fee_value"
            label="Taxa de Serviço do Escritório"
            hint="valor percentual (%) (max 100)"
          ></v-text-field>

        </v-col>
        <v-col md=12>
          <v-row>
            <v-col md=10>
              <v-select
                id="portfolioInput"
                name="portfolio"
                v-model="item.portfolio"
                :items="portfolios"
                item-text="name"
                item-value="value"
                label="Portfolio"
                hint=""
              ></v-select>

            </v-col>
            <v-col md=2>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      id="addPortfolioButtonSm"
                      class="mx-2"
                      fab
                      dark
                      small
                      color="default"
                      v-bind="attrs"
                      v-on="on"
                      link :to="'/type/portfolio/add'"
                    >
                      <v-icon dark>
                        mdi-plus
                      </v-icon>
                    </v-btn>
                </template>
                <span>Adicionar portfolio</span>
              </v-tooltip>

            </v-col>
            
          </v-row>


          <v-text-field
            id="cnpjInput"
            name="cnpj"
            label="CNPJ"
            v-model="item.cnpj"
            v-mask="'##.###.###/####-##'"
            hint="a máscara é automática"
          ></v-text-field>

          <v-text-field
            id="lawsuitValueInput"
            name="lawsuitValue"
            label="Valor do Caso"
            v-model="item.lawsuit_value"
            hint="centavos separados por . (ponto)"
          ></v-text-field>

          <v-text-field
            id="clientNumberInput"
            name="clientNumber"
            v-model="item.client_number"
            label="Nº do Cliente"
            hint="business number gyra+"
          ></v-text-field>

          <v-text-field
            id="caseInput"
            name="case"
            v-model="item.case"
            label="Nº do Caso"
            hint="nº inteiro"
          ></v-text-field>

          <v-text-field
            id="ccbInput"
            name="ccb"
            label="CCB"
            v-model="item.ccb"
            hint="pode ser mais de um separado por / (barra)"
          ></v-text-field>

          <v-text-field
            id="accoutInput"
            name="account"
            v-model="item.account"
            label="Nome da Conta"
            hint="account name (planilha)"
          ></v-text-field>

          <v-text-field
            id="companyNameInput"
            name="companyName"
            label="Empresa"
            hint="razão social (planilha)"
            v-model="item.company_name"
          ></v-text-field>


        </v-col>

        </v-row>
        
    </v-form>
</template>

<script>
  const {EventBus} = require('../../addons/event-bus.js')

  export default {
    name: 'LawsuitControlForm',
    props: ['item', 'roles', 'offices', 'portfolios'],
    data() {
      return {
        money: 0,
      }
    },
    methods: {
      submitAndSend() {
        this.item.status = 'sent'
        return EventBus.$emit('lawsuit-submitted')
      },
      submit() {
        return EventBus.$emit('lawsuit-submitted')
      },
    }
  }

</script>
