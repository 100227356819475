<template>
  <div v-if="items">
    <v-row>
      <v-col md="4">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Filtro"
          single-line
          hide-details
        ></v-text-field>

      </v-col>

      <v-col md="6">&nbsp;</v-col>

      <v-col md="2">
      </v-col>
    </v-row>
    <br>
    <br>


      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
      >
      <template v-slot:item="row">
          <tr>
            <td>
                <div v-html="row.item.case"></div>
                <v-icon  v-if="row.item.isNew" small>
                  mdi-star
                </v-icon>

            </td>
            <td v-html="row.item.name"></td>

            <td v-html='row.item.court_info'></td>
            <td v-html='row.item.numbers'></td>
            <td><status-table-display :dictionaryKey="row.item.status"></status-table-display></td>
            <td>
              <v-avatar v-if='row.item.unpaidCosts' color="red">
                <v-icon dark>
                  mdi-cash
                </v-icon>
              </v-avatar>
              <v-avatar v-if='!row.item.unpaidCosts' color="green">
                <v-icon dark>
                  mdi-cash
                </v-icon>
              </v-avatar>
            </td>
            <td v-html='row.item.created'></td>
            <td v-html='row.item.modified'></td>

            <td>
                <view-button 
                  view="small"
                  :path="'/lawsuit/view/' + row.item.id"
                ></view-button>
            </td>
          </tr>

      </template>
      </v-data-table>
  </div>
</template>

<script>
  import ViewButton from '../../components/ViewButton.vue'
  import StatusTableDisplay from '../../components/StatusTableDisplay.vue'
  const {displayLegalLawsuitTableHeaders} = require('../descriptors.js')

  export default {
    components: {
      ViewButton,
      StatusTableDisplay
    },
    name: 'LawsuitTable',
    props: ['items'],
    data() {
      return {
        search: '',
      }
    },
    computed: {
      headers() {
        return displayLegalLawsuitTableHeaders()
      }
    },
  };

</script>

<style scoped>
td {
  height:  90px !important;
}

</style>