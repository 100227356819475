<template>
  <div v-if="items">
    <!-- <costs-bar-chart></costs-bar-chart> -->
<!--     <pie-chart
      :legend="portfolio"
      :mode="count"
      :items="items">
    </pie-chart>
 --> </div>

</template>

<script>
  // const d3 = require('d3')
  // const nv = require('nvd3')
  // import CostsBarChart from './CostsBarChart'
  // import PieChart from '../../components/PieChart'
  export default {
    components: {
      // CostsBarChart,
      // PieChart,
    },
    name: 'LawsuitCharts',
    props: ['items'],
    mounted() {
      // console.log(d3,nv)
    }
  }

</script>

<style scoped>
td {
  height:  100px !important;
}
</style>