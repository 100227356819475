<template>
<v-row>
  <v-col md=6>

        <v-card>
            <v-row>
              <v-col md='10'>
                <h2 class="title">Documentos</h2>
              </v-col>
              <v-col md='2'>
                <v-tooltip  v-if="roles.filter(i => { return i.value === 'admin'}).length > 0" top>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        id="uploadFileButtonSm"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="default"
                        v-bind="attrs"
                        v-on="on"
                        link :to="'/lawsuit/file/' + $route.params.id + '/kit/add'"
                      >
                        <v-icon dark>
                          mdi-file-upload
                        </v-icon>
                      </v-btn>
                  </template>
                  <span>Adicionar documento</span>
                </v-tooltip>
              </v-col>
            </v-row>

        </v-card>

        <v-card>
      <v-list-item-group v-if="item.kit && item.kit.length > 0">
        <a :href="file.filepath" target="_blank" v-for="file in item.kit" :item="file" v-bind:key='file.refcode'>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-file</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="file.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        </a>
      </v-list-item-group>

          <v-card-title v-if="!item.kit || (item.kit && item.kit.length === 0)">
            <p>Nenhuma documento adicionado</p>
          </v-card-title>

        </v-card>

      </v-col>
      <v-col md=6>

        <v-card>

            <v-row>
              <v-col md='10'>
                <h2 class="title">Minuta</h2>
              </v-col>
              <v-col md='2'>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        id="uploadReviewButtonSm"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="default"
                        v-bind="attrs"
                        v-on="on"
                        link :to="'/lawsuit/file/' + $route.params.id + '/reviews/add'"
                      >
                        <v-icon dark>
                          mdi-file-upload
                        </v-icon>
                      </v-btn>
                  </template>
                  <span>Adicionar minuta</span>
                </v-tooltip>
              </v-col>
            </v-row>

        </v-card>
        
        <v-card>

          <v-list-item-group v-if="item.reviews && item.reviews.length > 0">
            <a :href="file.filepath" target="_blank" v-for="file in item.reviews" :item="file" v-bind:key='file.refcode'>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-file</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="file.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            </a>
          </v-list-item-group>

          <v-card-title v-if="!item.reviews || (item.reviews && item.reviews.length === 0)">
            <p>Nenhuma minuta adicionada</p>
          </v-card-title>

        </v-card>
  </v-col>


</v-row>
</template>

<script>
    export default {
        name: 'LawsuitControlFiles',
        props: ['item', 'roles'],
        data() {
            return {
            }
        },
    }
</script>

<style>
.cost-card {
  margin-bottom: 20px
}
</style>

