<template>
<v-container fluid>
  <v-row>
      <action-bar entity="lawsuit"></action-bar>
      <v-col md=11>
        <v-card>
          <v-card-title>
            {{title}}
          </v-card-title>

          <v-card-actions>
          <span v-if="!pk">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        id="listUserButtonSm"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="default"
                        v-bind="attrs"
                        v-on="on"
                        link :to="'/lawsuit'"
                      >
                        <v-icon dark>
                          mdi-briefcase
                        </v-icon>

                      </v-btn>
                  </template>
                  <span>Casos</span>
                </v-tooltip>

                
                <!-- 
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        id="listUserButtonSm"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="default"
                        v-bind="attrs"
                        v-on="on"
                        link :to="'/lawsuit/kanban'"
                      >
                        <v-icon dark>
                          mdi-clipboard
                        </v-icon>

                      </v-btn>
                  </template>
                  <span>KanBan</span>
                </v-tooltip> 
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        id="listUserButtonSm"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="default"
                        v-bind="attrs"
                        v-on="on"
                        link :to="'/lawsuit/charts'"
                      >
                        <v-icon dark>
                          mdi-chart-areaspline
                        </v-icon>

                      </v-btn>
                  </template>
                  <span>Gráficos</span>
                </v-tooltip> 
                -->

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        id="listUserButtonSm"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="default"
                        v-bind="attrs"
                        v-on="on"
                        link :to="'/lawsuit/costs'"
                      >
                        <v-icon dark>
                          mdi-cash
                        </v-icon>

                      </v-btn>
                  </template>
                  <span>Custas</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        id="listUserButtonSm"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="default"
                        v-bind="attrs"
                        v-on="on"
                        link :to="'/lawsuit/properties'"
                      >
                        <v-icon dark>
                          mdi-car
                        </v-icon>

                      </v-btn>
                  </template>
                  <span>Bens</span>
                </v-tooltip>


                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        id="listUserButtonSm"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="default"
                        v-bind="attrs"
                        v-on="on"
                        link :to="'/lawsuit/export'"
                      >
                        <v-icon dark>
                          mdi-export
                        </v-icon>

                      </v-btn>
                  </template>
                  <span>Exportar</span>
                </v-tooltip>




                
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        id="listUserButtonSm"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="default"
                        v-bind="attrs"
                        v-on="on"
                        link :to="'/lawsuit/import'"
                      >
                        <v-icon dark>
                          mdi-import
                        </v-icon>

                      </v-btn>
                  </template>
                  <span>Importar</span>
                </v-tooltip>





              </span>

          <span v-if="pk">


                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        id="listUserButtonSm"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="default"
                        v-bind="attrs"
                        v-on="on"
                        link :to="'/lawsuit/view/' + pk"
                      >
                        <v-icon dark>
                          mdi-briefcase
                        </v-icon>

                      </v-btn>
                  </template>
                  <span>Caso</span>
                </v-tooltip>


                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        id="listUserButtonSm"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="default"
                        v-bind="attrs"
                        v-on="on"
                        link :to="'/lawsuit/view/' + $route.params.id + '/documents'"
                      >
                        <v-icon dark>
                          mdi-file-document
                        </v-icon>

                      </v-btn>
                  </template>
                  <span>Documentos</span>
                </v-tooltip>


                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        id="listUserButtonSm"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="default"
                        v-bind="attrs"
                        v-on="on"
                        link :to="'/lawsuit/view/' + $route.params.id + '/history'"
                      >
                        <v-icon dark>
                          mdi-history
                        </v-icon>

                      </v-btn>
                  </template>
                  <span>Histórico</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        id="listUserButtonSm"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="default"
                        v-bind="attrs"
                        v-on="on"
                        link :to="'/lawsuit/view/' + pk + '/costs'"
                      >
                        <v-icon dark>
                          mdi-cash
                        </v-icon>

                      </v-btn>
                  </template>
                  <span>Custas</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        id="listUserButtonSm"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="default"
                        v-bind="attrs"
                        v-on="on"
                        link :to="'/lawsuit/view/' + pk + '/properties'"
                      >
                        <v-icon dark>
                          mdi-car
                        </v-icon>

                      </v-btn>
                  </template>
                  <span>Bens</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        id="listUserButtonSm"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="default"
                        v-bind="attrs"
                        v-on="on"
                        link :to="'/lawsuit/view/' + pk + '/export'"
                      >
                        <v-icon dark>
                          mdi-export
                        </v-icon>

                      </v-btn>
                  </template>
                  <span>Exportar</span>
                </v-tooltip>

                <v-tooltip top v-if="(view === 'edit' || view === 'view') && item.status !== 'acordo-em-andamento'">
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        id="listUserButtonSm"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="default"
                        v-bind="attrs"
                        v-on="on"
                        link :to="'/lawsuit/view/' + pk + '/cost/add'"
                      >
                        <v-icon dark>
                          mdi-account-cash
                        </v-icon>

                      </v-btn>
                  </template>
                  <span>Adicionar Custa</span>
                </v-tooltip>


                <v-tooltip top v-if="(view === 'edit' || view === 'view')">
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        id="listUserButtonSm"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="default"
                        v-bind="attrs"
                        v-on="on"
                        link :to="'/lawsuit/view/' + pk + '/property/add'"
                      >
                        <v-icon dark>
                          mdi-car-arrow-right
                        </v-icon>

                      </v-btn>
                  </template>
                  <span>Adicionar Bem</span>
                </v-tooltip>

              </span>
              
              <save-button v-if="view === 'edit' || view === 'create'" view="big" @save="submit()"></save-button>

          </v-card-actions>
        </v-card>

        <div v-if="!$store.state.loading">

            <br>
            <br>

            <lawsuit-control-filters
              v-if="items && role === 'internal' && (!view || view === 'charts')"
              :items="items"></lawsuit-control-filters>

            <lawsuit-filters
              v-if="items && role === 'legal' && (!view || view === 'charts')"
             :items="items"></lawsuit-filters>

            <lawsuit-charts
              :items="items"
              v-if="items &&
                    view === 'charts'"
            ></lawsuit-charts>

            <lawsuit-control-table
              v-if="items &&
                    role === 'internal' &&
                    !view"
              :items="items"
              :headers="headers"
            ></lawsuit-control-table>

            <lawsuit-table
              v-if="items &&
                    role === 'legal' &&
                    !view"
              :items="items"
              :headers="headers"
            ></lawsuit-table>

            <cost-report-table
              v-if="items &&
                    view === 'costs' && !pk"
              :items="items"></cost-report-table>

<!-- 
            <cost-form
              v-if="items &&
                    view === 'costs' && !pk"
              :items="items"></cost-form>
 -->
            <cost-card-list
              v-if="item && (
                      (view === 'view' && tab === 'costs') ||
                      (view === 'view' && tab === 'cost')
                    )"
              :items="[item]" :role='role'></cost-card-list>

            <seized-property-report-table
              v-if="items &&
                    role === 'internal' &&
                    view === 'properties'"
              :items="items"></seized-property-report-table>

            <raw-api-response
              :raw="raw_item"
              v-if="$route.params.action === 'dev' && $route.params.id"
            ></raw-api-response>

            <lawsuit-control-detail
              :item="item"
              :offices="offices"
              :portfolios="portfolios"
              :lawsuitStatus="lawsuitStatus"
              :legalStatus="lawsuitStatus"
              :role="role"
              :roles="roles"
              :provinces="provinces"
              v-if="item && (view === 'view' || view === 'create' || view === 'edit') && !tab"
            ></lawsuit-control-detail>

            <lawsuit-history
              :roles="roles"
              :item="item"
              v-if="item && view === 'view' && tab === 'history' && pk"
            ></lawsuit-history>

          <lawsuit-control-files
            :item="item"
            :raw="raw_item"
            :roles="roles"
              v-if="item && view === 'view' && tab === 'documents' && pk"
          ></lawsuit-control-files>

          <lawsuit-spreadsheet
            v-if="items && view === 'export'"
            :items="raw_items"></lawsuit-spreadsheet>

          <lawsuit-spreadsheet
            v-if="item && view === 'view' && tab === 'export' && pk"
            :items="[raw_item]"></lawsuit-spreadsheet>

       </div>
      
      <div v-if="$store.state.loading">  
          <v-img
            alt=""
            class="shrink mr-2"
            contain
            :src="'/static/illustrations/' + image + '.svg'"
            width="100%"
            icon
          />
      </div>

    </v-col>
  </v-row>
</v-container>
</template>
<script>

const mixins = require('../../addons/mixins.js')
const {
  internalLawsuitModel,
  displayLegalLawsuit,
  displayLegalLawsuitForm,
  displayInternalLawsuit,
  displayInternalLawsuitForm,
  displayLegalLawsuitTableHeaders,
  displayInternalLawsuitTableHeaders,
  displayLegalLawsuitListItem,
  displayInternalLawsuitListItem,
  processInternalLawsuit,
  processCost,
  processLegalLawsuit,
  costSchema,
  internalLawsuitSchema,
  legalLawsuitSchema,
} = require('../descriptors.js')

const {EventBus} = require('../../addons/event-bus.js')

import ActionBar from '../../components/ActionBar'
import SaveButton from '../../components/SaveButton'
// import LawsuitControlForm from '../components/LawsuitControlForm'
import LawsuitCharts from '../components/LawsuitCharts'
// import LawsuitForm from '../components/LawsuitForm'
// import CostForm from '../components/CostForm'
// import CostDetail from '../components/CostDetail'
import LawsuitControlDetail from '../components/LawsuitControlDetail'
import LawsuitControlFiles from '../components/LawsuitControlFiles'
import LawsuitControlTable from '../components/LawsuitControlTable'
import LawsuitControlFilters from '../components/LawsuitControlFilters'
import LawsuitFilters from '../components/LawsuitFilters'
import LawsuitHistory from '../components/LawsuitHistory'
import LawsuitTable from '../components/LawsuitTable'
import CostReportTable from '../components/CostReportTable.vue'
import CostCardList from '../components/CostCardList.vue'
import SeizedPropertyReportTable from '../components/SeizedPropertyReportTable.vue'
import LawsuitSpreadsheet from '../components/LawsuitSpreadsheet.vue'
import RawApiResponse from '../../gyra/components/RawApiResponse.vue'
 
export default {
  name: 'Lawsuit',
  components: {
      ActionBar,  
      SaveButton,
      LawsuitControlFilters,
      LawsuitFilters,
      LawsuitControlTable,
      LawsuitTable,
      // LawsuitControlForm,
      // LawsuitForm,
      // CostForm,
      // CostDetail,
      LawsuitCharts,
      LawsuitControlDetail,
      CostReportTable,
      CostCardList,
      SeizedPropertyReportTable,
      LawsuitSpreadsheet,
      LawsuitControlFiles,
      RawApiResponse,
      LawsuitHistory
  },
  mixins: [
    mixins.defaultEvents,
    mixins.defaultMethods,
    mixins.routeEvents,
    mixins.routeMethods,
  ],
  data() {
    return {
      'name': 'Caso',
      'plural_name': 'Casos',
      'image': 'Coworking',
      'entity': 'lawsuit',
      'redir': '/lawsuit'
    }
  },
  computed: {
    title: function () {
      if (this.item && this.view === 'create' && !this.pk) {
        return 'Adicionar novo ' + this.name
      }
      if (this.item && this.view === 'edit' && this.pk) {
        return 'Editando '  + this.name + ' ' + this.item.case 
      }
      if (this.item && this.view === 'view' && this.pk) {
        const showOffice = this.roles.filter(i => { return i.value === 'admin'}).length > 0 && this.item.office && this.item.office.name
        const office = showOffice ? this.item.office.name : ''
        return  this.name + ' ' + this.item.case + ' ' + office
      }
      return this.plural_name
    },
    showInternalForm: function () {
      return this.item &&
        this.role === 'internal' &&
        (
          (this.view === 'edit' && this.pk) ||
          (this.view === 'create' && !this.pk)
        )
    },
    showLegalForm: function () {
      return this.item &&
        this.role === 'legal' &&
        (this.view === 'edit' && this.pk)
    },
    showInternalTable: function () {
      return this.items && this.role === 'internal' && !this.view
    },
    showLegalTable: function () {
      return this.items && this.role === 'legal' && !this.view
    },
    showDetail: function () {
      return this.item && this.view === 'view'
    },
    showCodeEditor: function () {
      return this.view === 'dev' && this.roles.map(i => {return i.value}).includes('admin') && this.pk
    },
    pk: function() {
      return this.$route.params.id
    },
    tab: function() {
      return this.$route.params.tab
    },
    tabAction: function() {
      return this.$route.params.tabAction
    },
    view: function() {
      return this.$route.params.action
    },
    roles: function() {
      return this.$store.getters.profileRoles
    },
    role: function() {
      if (this.roles.map(i => {return i.value}).includes('lawyer')) {
        return 'legal'
      }
      return 'internal'
    },
    headers: function() {
      if (this.role === 'internal') {
        return displayInternalLawsuitTableHeaders()
      }
      return displayLegalLawsuitTableHeaders()
    },
    addAction: function() {
      if (this.role === 'internal') {
        return 'addInternalLawsuit'
      }
      return false
    },
    updateAction: function() {
      if (this.role === 'internal') {
        return 'updateInternalLawsuit'
      }
      return 'updateLegalLawsuit'
    },
    removeAction: function() {
      if (this.role === 'internal') {
        return 'removeInternalLawsuit'
      }
      return false
    },
    loadAction: function() {
      if (this.role === 'internal') {
        return 'getInternalLawsuit'
      }
      return 'getLegalLawsuit'
    },
    loadAllAction: function() {
      if (this.role === 'internal') {
        return 'getInternalLawsuits'
      }
      return 'getLegalLawsuits'
    },
    addCostAction: function() {
      let action = 'addLegalLawsuitCost'
      if (this.role !== 'legal') {
        action = 'addInternalLawsuitCost'
      }
      return action
    },
    updateCostAction: function() {
      let action = 'updateLegalLawsuitCost'
      if (this.role !== 'legal') {
        action = 'updateInternalLawsuitCost'
      }
      return action
    },
    processed: function() {
      if (this.role === 'internal') {
        return processInternalLawsuit
      }
      return processLegalLawsuit
    },
    schema: function() {
      if (this.role === 'internal') {
        return internalLawsuitSchema
      }
      return legalLawsuitSchema
    },
    raw_items: function() {
      if (this.role === 'internal') {
        return this.$store.getters.lawsuitList
      }
      return this.$store.getters.lawsuitList.filter(i => {
          return i.status !== 'created' && i.status !== 'não-ajuizar'
      })
    },
    items: function() {
      if (this.role === 'internal') {
        return this.$store.getters.lawsuitList.map(i => {
          return displayInternalLawsuitListItem(i, this.$store.getters.dictionary)
        })
      }
      return this.$store.getters.lawsuitList
        .filter(i => {
          return i.status != 'created' && i.status != 'não-ajuizar'
        })
        .map(i => {
          return displayLegalLawsuitListItem(i, this.$store.getters.dictionary)
        })
    },
    raw_item: function() {
      const lawsuit = this.$store.getters.currentLawsuit
      return lawsuit
    },
    item: function() {
      const lawsuit = this.$store.getters.currentLawsuit
      if (this.role === 'internal') {
        if (this.view === 'create') {
          return internalLawsuitModel()
        }
        if (this.view === 'view') {
          return displayInternalLawsuit(lawsuit, this.$store.getters.dictionary)
        }
        if (this.view === 'edit') {
          return displayInternalLawsuitForm(lawsuit, this.$store.getters.dictionary)
        }
      }
      if (this.role === 'legal') {
        if (this.view === 'create') {
          return false
        }      
        if (this.view === 'view') {
          const t = displayLegalLawsuit(lawsuit, this.$store.getters.dictionary)
          return t
        }
        if (this.view === 'edit') {
          return displayLegalLawsuitForm(lawsuit, this.$store.getters.dictionary)
        }
      }
      return null
    },
    // LAWSUIT SPECIFIC
    offices: function() {
      return this.$store.getters.officeSelectList
    },
    portfolios: function() {
        const typing = this.$store.getters.typingSelectList
        return typing.filter(i => { return i.scope === 'portfolio'})
    },
    provinces: function() {
        const typing = this.$store.getters.typingSelectList
        return typing.filter(i => { return i.scope === 'province'})
    },
    lawsuitStatus: function() {
        const typing = this.$store.getters.typingSelectList
        return typing.filter(i => { return i.scope === 'lawsuit-legal-status'})
    },
  },
  mounted() {
    EventBus.$on('comment-submitted', this.submitComment)
    EventBus.$on('cost-submitted', this.submitCost)
    EventBus.$on('seized-property-submitted', this.submit)

    EventBus.$on('status-change-submitted', this.changeStatus)
    EventBus.$on('seized-property-removal-submitted', this.removeSeizedProperty)
    EventBus.$on('cost-removal-submitted', this.removeCost)
    EventBus.$on('cost-removed', this.load)
    this.runLoad()
  },
  beforeDestroy() {
    EventBus.$off('comment-submitted')
    EventBus.$off('cost-submitted')
    EventBus.$off('seized-property-submitted')

    EventBus.$off('status-change-submitted')
    EventBus.$off('seized-property-removal-submitted')
    EventBus.$off('cost-removal-submitted')
    EventBus.$off('cost-removed')
  },
  methods: {
    // HOOKS
    onEntitySelected() {
      const pk = this.$route.params.id
      const p1 = this.$store.dispatch(this.loadAction, {pk})
      return p1.then(r => {
        this.$store.commit('setReady')
        return r
      })
    },
    // OVERRIDES
    loadAll() {
        let promises = []
        if (this.role === 'internal' && this.$store.getters.findAll('office').length === 0) {
          const entity = 'office'
          const p1 = this.$store.dispatch('getEntities', {prefix: entity})
          promises.push(p1)
          console.log('teste1', p1)
        }
        const p2 = this.$store.dispatch(this.loadAllAction)
        promises.push(p2)
        return Promise.all(promises).then(r => {
          this.$store.commit('setReady')
          console.log('teste2', this.offices)
          return r
        })
    },
    load() {
        const pk = this.$route.params.id
        let promises = []
        if (this.role === 'internal') {
          const entity = 'office'
          const p1 = this.$store.dispatch('getEntities', {prefix: entity})
          if (this.role != 'legal') {
            promises.push(p1)
          }
        }
        const p2 = this.$store.dispatch(this.loadAction, {pk})
        promises.push(p2)
        return Promise.all(promises).then(r => {
          this.$store.commit('setReady')
          return r
        })
    },
    // COMPONENT SPECIFIC
    changeStatus: function ({status, pk}) {
      const item = this.processed(this.raw_item)
      item.status = status
      const p1 = this.$store.dispatch(this.updateAction, {item, pk})
      return p1.then(r => {
        this.$store.commit('loadStatus', status)
        this.$store.commit('setReady')
        return r
      })
    },
    submitComment ({comment}) {
      const refcode = this.$route.params.refcode
      const p1 = this.$store.dispatch('commentLawsuit', {item: comment, pk: this.pk, refcode})
      const p2 = this.$store.dispatch(this.loadAction, {pk: this.pk})
      p1.then(r => {
        this.$store.commit('setReady')
        this.$router.go()
        return r
      })
      return p1.then(p2.then(r => {
        this.$store.commit('pushEntity', {entity: 'lawsuit', pk: this.pk})
        return r
      }))
    },
    submitCost ({cost}) {
      const refcode = this.$route.params.refcode
      const item = processCost(cost)
      let action        
      action = this.addCostAction
      if (refcode) {
        action = this.updateCostAction
      }
      costSchema.validate(item)
        .then((valid) => {
          const p1 = this.$store.dispatch(action, {item, pk: this.pk, refcode})
          p1.then(r => {
            this.$store.commit('setReady')
            this.$router.push({path: '/lawsuit/view/' + this.pk + '/costs'})
            return r
          })
          return valid
        })
        .catch((err) => {
          EventBus.$emit('notification', err)
          return err
        })
    },
    removeCost: function (refcode) {
      let action = 'removeInternalLawsuitCost'
      if (this.role === 'internal') {
        action = 'removeInternalLawsuitCost'
      }
      const pk = this.$route.params.id
      const p1 = this.$store.dispatch(action, {pk, refcode})
      return p1.then(r => {
        this.$store.commit('setReady')
        return r
      })
    },
    removeSeizedProperty: function(refcode) {
      let action = 'removeInternalLawsuitSeizedProperty'
      if (this.role === 'internal') {
        action = 'removeInternalLawsuitSeizedProperty'
      }
      const pk = this.$route.params.id
      const p1 = this.$store.dispatch(action, {pk, refcode})
      return p1.then(r => {
        this.$store.commit('setReady')
        return r
      })
    },
  }
}
</script>
