<template>
    <div>
        <v-row v-if="$route.params.tab === 'cost'">
            <v-col md=7>
                <v-card>
                    <cost-form class="mb-3" v-if="selected" :item="selected" :costTypes='costTypes' :costStatus='costStatus'>
                    </cost-form>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="$route.params.tab === 'costs'">
            <v-col v-for="item in costItems" v-bind:key="item.refcode" md=4>
                <cost-card class="mb-3" :item="item">
                </cost-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import CostForm from './CostForm.vue'
    import CostCard from './CostCard.vue'
    const {displayCostForm, displayCostReportTable} = require('../descriptors.js')

    export default {
        components: {
            CostCard,
            CostForm
        },
        props: ['items', 'role'],
        computed: {
            costTypes: function() {
                const typing = this.$store.getters.typingSelectList
                return typing.filter(i => { return i.scope === 'cost-type' })
            },
            costStatus: function() {
                const typing = this.$store.getters.typingSelectList
                return typing.filter(i => { return i.scope === 'cost-status' })
            },
            selected: function() {
                if (!this.$route.params || !this.$route.params.refcode) {
                  return {}
                }

                const costs = this.$store.getters.currentLawsuit.costs
                if (!costs) {
                  return {}
                }
                const cost = costs.filter(i => {return i.refcode === this.$route.params.refcode})
                return displayCostForm(cost[0])
            },
            costItems() {
                return displayCostReportTable(this.items)
            },
        }
    }
</script>
