var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('action-bar',{attrs:{"entity":"lawsuit"}}),_c('v-col',{attrs:{"md":"11"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-card-actions',[(!_vm.pk)?_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"id":"listUserButtonSm","fab":"","dark":"","small":"","color":"default","link":"","to":'/lawsuit'}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-briefcase ")])],1)]}}],null,false,4097283177)},[_c('span',[_vm._v("Casos")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"id":"listUserButtonSm","fab":"","dark":"","small":"","color":"default","link":"","to":'/lawsuit/costs'}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-cash ")])],1)]}}],null,false,1604298153)},[_c('span',[_vm._v("Custas")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"id":"listUserButtonSm","fab":"","dark":"","small":"","color":"default","link":"","to":'/lawsuit/properties'}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-car ")])],1)]}}],null,false,1571295929)},[_c('span',[_vm._v("Bens")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"id":"listUserButtonSm","fab":"","dark":"","small":"","color":"default","link":"","to":'/lawsuit/export'}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-export ")])],1)]}}],null,false,2997408008)},[_c('span',[_vm._v("Exportar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"id":"listUserButtonSm","fab":"","dark":"","small":"","color":"default","link":"","to":'/lawsuit/import'}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-import ")])],1)]}}],null,false,2121643016)},[_c('span',[_vm._v("Importar")])])],1):_vm._e(),(_vm.pk)?_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"id":"listUserButtonSm","fab":"","dark":"","small":"","color":"default","link":"","to":'/lawsuit/view/' + _vm.pk}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-briefcase ")])],1)]}}],null,false,2321248340)},[_c('span',[_vm._v("Caso")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"id":"listUserButtonSm","fab":"","dark":"","small":"","color":"default","link":"","to":'/lawsuit/view/' + _vm.$route.params.id + '/documents'}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-file-document ")])],1)]}}],null,false,1244973201)},[_c('span',[_vm._v("Documentos")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"id":"listUserButtonSm","fab":"","dark":"","small":"","color":"default","link":"","to":'/lawsuit/view/' + _vm.$route.params.id + '/history'}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-history ")])],1)]}}],null,false,2196916457)},[_c('span',[_vm._v("Histórico")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"id":"listUserButtonSm","fab":"","dark":"","small":"","color":"default","link":"","to":'/lawsuit/view/' + _vm.pk + '/costs'}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-cash ")])],1)]}}],null,false,4110278687)},[_c('span',[_vm._v("Custas")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"id":"listUserButtonSm","fab":"","dark":"","small":"","color":"default","link":"","to":'/lawsuit/view/' + _vm.pk + '/properties'}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-car ")])],1)]}}],null,false,343554319)},[_c('span',[_vm._v("Bens")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"id":"listUserButtonSm","fab":"","dark":"","small":"","color":"default","link":"","to":'/lawsuit/view/' + _vm.pk + '/export'}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-export ")])],1)]}}],null,false,2504042686)},[_c('span',[_vm._v("Exportar")])]),((_vm.view === 'edit' || _vm.view === 'view') && _vm.item.status !== 'acordo-em-andamento')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"id":"listUserButtonSm","fab":"","dark":"","small":"","color":"default","link":"","to":'/lawsuit/view/' + _vm.pk + '/cost/add'}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-account-cash ")])],1)]}}],null,false,3547416110)},[_c('span',[_vm._v("Adicionar Custa")])]):_vm._e(),((_vm.view === 'edit' || _vm.view === 'view'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"id":"listUserButtonSm","fab":"","dark":"","small":"","color":"default","link":"","to":'/lawsuit/view/' + _vm.pk + '/property/add'}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-car-arrow-right ")])],1)]}}],null,false,1340144958)},[_c('span',[_vm._v("Adicionar Bem")])]):_vm._e()],1):_vm._e(),(_vm.view === 'edit' || _vm.view === 'create')?_c('save-button',{attrs:{"view":"big"},on:{"save":function($event){return _vm.submit()}}}):_vm._e()],1)],1),(!_vm.$store.state.loading)?_c('div',[_c('br'),_c('br'),(_vm.items && _vm.role === 'internal' && (!_vm.view || _vm.view === 'charts'))?_c('lawsuit-control-filters',{attrs:{"items":_vm.items}}):_vm._e(),(_vm.items && _vm.role === 'legal' && (!_vm.view || _vm.view === 'charts'))?_c('lawsuit-filters',{attrs:{"items":_vm.items}}):_vm._e(),(_vm.items &&
                    _vm.view === 'charts')?_c('lawsuit-charts',{attrs:{"items":_vm.items}}):_vm._e(),(_vm.items &&
                    _vm.role === 'internal' &&
                    !_vm.view)?_c('lawsuit-control-table',{attrs:{"items":_vm.items,"headers":_vm.headers}}):_vm._e(),(_vm.items &&
                    _vm.role === 'legal' &&
                    !_vm.view)?_c('lawsuit-table',{attrs:{"items":_vm.items,"headers":_vm.headers}}):_vm._e(),(_vm.items &&
                    _vm.view === 'costs' && !_vm.pk)?_c('cost-report-table',{attrs:{"items":_vm.items}}):_vm._e(),(_vm.item && (
                      (_vm.view === 'view' && _vm.tab === 'costs') ||
                      (_vm.view === 'view' && _vm.tab === 'cost')
                    ))?_c('cost-card-list',{attrs:{"items":[_vm.item],"role":_vm.role}}):_vm._e(),(_vm.items &&
                    _vm.role === 'internal' &&
                    _vm.view === 'properties')?_c('seized-property-report-table',{attrs:{"items":_vm.items}}):_vm._e(),(_vm.$route.params.action === 'dev' && _vm.$route.params.id)?_c('raw-api-response',{attrs:{"raw":_vm.raw_item}}):_vm._e(),(_vm.item && (_vm.view === 'view' || _vm.view === 'create' || _vm.view === 'edit') && !_vm.tab)?_c('lawsuit-control-detail',{attrs:{"item":_vm.item,"offices":_vm.offices,"portfolios":_vm.portfolios,"lawsuitStatus":_vm.lawsuitStatus,"legalStatus":_vm.lawsuitStatus,"role":_vm.role,"roles":_vm.roles,"provinces":_vm.provinces}}):_vm._e(),(_vm.item && _vm.view === 'view' && _vm.tab === 'history' && _vm.pk)?_c('lawsuit-history',{attrs:{"roles":_vm.roles,"item":_vm.item}}):_vm._e(),(_vm.item && _vm.view === 'view' && _vm.tab === 'documents' && _vm.pk)?_c('lawsuit-control-files',{attrs:{"item":_vm.item,"raw":_vm.raw_item,"roles":_vm.roles}}):_vm._e(),(_vm.items && _vm.view === 'export')?_c('lawsuit-spreadsheet',{attrs:{"items":_vm.raw_items}}):_vm._e(),(_vm.item && _vm.view === 'view' && _vm.tab === 'export' && _vm.pk)?_c('lawsuit-spreadsheet',{attrs:{"items":[_vm.raw_item]}}):_vm._e()],1):_vm._e(),(_vm.$store.state.loading)?_c('div',[_c('v-img',{staticClass:"shrink mr-2",attrs:{"alt":"","contain":"","src":'/static/illustrations/' + _vm.image + '.svg',"width":"100%","icon":""}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }