<template>
  <v-form>
    <v-row>
        <v-col md=2>
          <br>
              <v-text-field
                id="caseInput"
                name="case"
                v-model="item.case"
                label="Nº do Caso"
                hint="nº inteiro"
              ></v-text-field>
        </v-col>
        <v-col md=2>
          <br>
              <v-select
                id="statusInput"
                name="status"
                v-model="item.status"
                :items="status"
                item-text="name"
                item-value="value"
                label="Status"
                hint=""
              ></v-select>
        </v-col>

         <v-col md=2>
          <label>Criado</label>
              <v-select
                id="createdSinceInput"
                name="createdSince"
                v-model="item.createdSince"
                :items="dates.reverse()"
                item-text="name"
                item-value="value"
                label="Desde"
                hint=""
              ></v-select>
              <v-select
                id="createdUntilInput"
                name="createdUntil"
                v-model="item.createdUntil"
                :items="dates"
                item-text="name"
                item-value="value"
                label="Até"
                hint=""
              ></v-select>
        </v-col>
       <v-col md=2>
          <label>Modificado</label>
              <v-select
                id="modifiedSinceInput"
                name="modifiedSince"
                v-model="item.modifiedSince"
                :items="dates.reverse()"
                item-text="name"
                item-value="value"
                label="Desde"
                hint=""
              ></v-select>
              <v-select
                id="modifiedUntilInput"
                name="modifiedUntil"
                v-model="item.modifiedUntil"
                :items="dates"
                item-text="name"
                item-value="value"
                label="Até"
                hint=""
              ></v-select>
        </v-col>

         <v-col md=2>

        <v-btn
          id="searchButton"
          class="mx-2"
          fab
          dark
          small
          color="default"
          @click="search()"
        >
          <v-icon dark>
            mdi-magnify
          </v-icon>
        </v-btn>
        <v-btn
          id="clearButton"
          class="mx-2"
          fab
          dark
          small
          color="default"
          @click="clear()"
        >
          <v-icon dark>
            mdi-broom
          </v-icon>
        </v-btn>
        </v-col>
     </v-row>



  </v-form>
  
</template>

<script>
const {EventBus} = require('../../addons/event-bus.js')

export default {
    name: 'LawsuitFilters',
    props: ['items'],
    computed: {
      item() {
        return this.$store.state.entities.lawsuit.filters
      },
      offices: function() {
        return this.$store.getters.officeSelectList
      },
      portfolios() {
        const typing = this.$store.getters.typingSelectList
        return typing.filter(i => i.scope === 'portfolio')
      },
      values() {
        return [
          {name: '0', value: 0},
          {name: 'R$ 10 mil', value: 10000},
          {name: 'R$ 20 mil', value: 20000},
          {name: 'R$ 30 mil', value: 30000},
          {name: 'R$ 40 mil', value: 40000},
          {name: 'R$ 50 mil', value: 50000},
          {name: 'R$ 75 mil', value: 75000},
          {name: 'R$ 100 mil', value: 100000},
        ]
      },
      dates() {
        const getDaysAgo = (days) => {
          const d = new Date()
          return new Date(d.setDate(d.getDate() - days)).toLocaleDateString('pt-br')
        }
        return [
          {name: 'Hoje - ' + getDaysAgo(0), value: 0},
          {name: 'Ontem - ' + getDaysAgo(1), value: 1},
          {name: 'Última semana - ' + getDaysAgo(7), value: 7},
          {name: 'Último mês - ' + getDaysAgo(30), value: 30},
          {name: 'Últimos 3 meses - ' + getDaysAgo(30 * 3), value: 30 * 3},
          {name: 'Últimos 6 meses - ' + getDaysAgo(30 * 6), value: 30 * 6},
          {name: 'Últimos 12 meses - ' + getDaysAgo(365), value: 365},
        ]
      },
      status() {
        const typing = this.$store.getters.typingSelectList
        return typing.filter(i => i.scope === 'lawsuit-legal-status')
      },
    },
    methods: {
      clear() {
        this.$store.commit('resetLawsuitFilters')
      },
      search() {
        let filters = {}
        const item = this.item
        for (const prop in item) {
          if (item[prop]) {
            filters[prop] = item[prop]
          }
        }
        EventBus.$emit('lawsuit-search-submitted', filters)
      }
    }
  }
</script>
