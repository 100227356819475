<template>
  <v-card elevation="1" class="cost-card" color="#eee">

        <v-card-text align="center">
          <h2 class="text--primary">
            {{item.value}}
          </h2>
        </v-card-text>


    <v-card elevation="0" class="ma-4" color="#ddd">
      <v-card-actions>
            <status-table-display :dictionaryKey="item.type"></status-table-display>
           <status-table-display class="display-inline ml-3" :dictionaryKey="item.status"></status-table-display> 
      </v-card-actions>

    </v-card>

   <v-card elevation="0" class="ma-4" color="#ddd">
        <v-row v-if="item.billet">
          <v-col md=5>
            
           <v-card-actions>
              <a class="smaller-font" :href="item.billet.filepath" target="_blank">
                  <v-icon>mdi-file</v-icon>Boleto
              </a>
          </v-card-actions>
        </v-col>
        <v-col md=7>
          
           <v-card-actions>
              <span class="smaller-font" v-if="item.billet.uploaded_by">
                  <contact-information :contact="item.billet.uploaded_by"></contact-information>                  
              </span>
          </v-card-actions>
              
        </v-col>
      </v-row>

           <v-row v-if="item.receipt">
          <v-col md=5>
            
           <v-card-actions>
              <a class="smaller-font" :href="item.receipt.filepath" target="_blank">
                  <v-icon>mdi-file</v-icon>Recibo
              </a>
          </v-card-actions>
        </v-col>
        <v-col md=7>
          
           <v-card-actions>
              <span class="smaller-font" v-if="item.receipt.uploaded_by">
                  <contact-information :contact="item.receipt.uploaded_by"></contact-information>                  
              </span>
          </v-card-actions>
              
        </v-col>
      </v-row>
        <v-row v-if="item.transfer_receipt">
          <v-col md=5>
            
           <v-card-actions>
              <a class="smaller-font" :href="item.transfer_receipt.filepath" target="_blank">
                  <v-icon>mdi-file</v-icon>Comprovante
              </a>
          </v-card-actions>
        </v-col>
        <v-col md=7>
          
           <v-card-actions>
              <span class="smaller-font" v-if="item.transfer_receipt.uploaded_by">
                  <contact-information :contact="item.transfer_receipt.uploaded_by"></contact-information>                  
              </span>
           </v-card-actions>
              
        </v-col>
      </v-row>

    </v-card>


    <v-card elevation="0" class="ma-4" color="#ddd">
      <v-card-text v-if="!$route.params.id">
        <div class="text--primary">
          {{item.company_name}}
          <p>ccb {{item.ccb}}<br>
          cnpj {{item.cnpj}}<br>
        </p>
        </div>
      </v-card-text>
    </v-card>

    <v-card-actions v-if="!noaction">
      <edit-button 
        view="small"
        :path="'/lawsuit/view/' + item.lawsuit_id + '/cost/edit/' + item.refcode"
      ></edit-button>

        <v-tooltip bottom v-if="!item.billet">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              x-small
              v-bind="attrs"
              v-on="on"
              link :to="'/lawsuit/file/' + item.lawsuit_id + '/cost/' + item.refcode + '/billet/add'"
            >
              <v-icon>mdi-cash</v-icon>
            </v-btn>
          </template>
          <span>Boleto</span>
        </v-tooltip>

        <v-tooltip bottom v-if="!item.receipt">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              x-small
              v-bind="attrs"
              v-on="on"
              link :to="'/lawsuit/file/' + item.lawsuit_id + '/cost/' + item.refcode + '/receipt/add'"
            >
              <v-icon>mdi-ticket-outline</v-icon>
            </v-btn>
          </template>
          <span>Recibo</span>
        </v-tooltip>

        <v-tooltip bottom v-if="!item.transfer_receipt">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              x-small
              v-bind="attrs"
              v-on="on"
              link :to="'/lawsuit/file/' + item.lawsuit_id + '/cost/' + item.refcode + '/transfer_receipt/add'"
            >
              <v-icon>mdi-ticket</v-icon>
            </v-btn>
          </template>
          <span>Comprovante</span>
        </v-tooltip>     

    </v-card-actions>


  </v-card>

</template>
<script>
  import ContactInformation from './ContactInformation.vue'
  import EditButton from '../../components/EditButton'  
  import StatusTableDisplay from '../../components/StatusTableDisplay'  
  export default {
    components: {
      StatusTableDisplay,
      EditButton,
      ContactInformation
    },
    props: ['item', 'role', 'noaction'],
  }
</script>
<style>
 .display-inline {
  display: inline;
 } 
  .cost-card {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid #ddd !important;

  }
  .bb {
  }
  .smaller-font {
    font-size: 0.8em;
  }
</style>