<template>
      <v-form v-if="item" autocomplete="off">
        <v-col cols="8">
          <v-row>
            <v-col md="10">
              <v-select
                id="statusInput"
                name="costStatus"
                v-model="item.status"
                item-text="name"
                item-value="value"
                :items="costStatus"
                label="Status"
                hint=""
              ></v-select>
            </v-col>

            <v-col md="2">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      id="addCostStatusButtonSm"
                      class="mx-2"
                      fab
                      dark
                      small
                      color="default"
                      v-bind="attrs"
                      v-on="on"
                      link :to="'/type/cost-status/add'"
                    >
                      <v-icon dark>
                        mdi-plus
                      </v-icon>
                    </v-btn>
                </template>
                <span>Adicionar status de custa</span>
              </v-tooltip>

            </v-col>
          </v-row>

          <v-row>
            <v-col md="10">
              <v-select
                id="costTypeInput"
                name="costType"
                v-model="item.cost_type"
                item-text="name"
                item-value="value"
                :items="costTypes"
                label="Tipo de Custa"
                hint=""
              ></v-select>
            </v-col>

            <v-col md="2">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      id="addCostButtonSm"
                      class="mx-2"
                      fab
                      dark
                      small
                      color="default"
                      v-bind="attrs"
                      v-on="on"
                      link :to="'/type/cost-type/add'"
                    >
                      <v-icon dark>
                        mdi-plus
                      </v-icon>
                    </v-btn>
                </template>
                <span>Adicionar tipo de custa</span>
              </v-tooltip>

            </v-col>
          </v-row>

          <v-text-field
            id="valueInput"
            name="value"
            v-model="item.value"
            label="Valor"
            hint="em R$, centavos separados por . (ponto)"
          ></v-text-field>
      
        </v-col>
        <save-button @save="submit()"></save-button>

      </v-form>
</template>

<script>
  import SaveButton from '../../components/SaveButton'
  const {EventBus} = require('../../addons/event-bus.js')
  
  export default {
    name: 'CostForm',
    components: {
      SaveButton
    },
    props: ['item', 'costTypes', 'costStatus'],
    methods: {
      submit() {
        const params = {cost: this.item}
        EventBus.$emit('cost-submitted', params)
      }
    },
    computed: {
      roles: function () {
        return this.$store.getters.profileRoles.map(i => {return i.value})
      }
    },
  }
</script>
