<template>
    <div v-if="contact">
<!--         <a v-if="contact.phone" :href="'https://api.whatsapp.com/send?phone=' + contact.phone + '&text='" target="_blank">
            <v-icon>mdi-whatsapp</v-icon>
        </a>
 -->        <a v-if="contact.email" :href="'mailto:' + contact.email" target="_blank">
            <v-icon>mdi-email</v-icon>
            {{contact.name}}
        </a>
    </div>
</template>
<script>
    export default {
        props: ['contact']
    }   
</script>
