const yup = require('yup')
const { setLocale } = require('yup');
const helpers = require('../addons/helpers');
const {
    displayContactInformation,
    displayFile,
    displayComment,
    displayActivity,
    displayHistory
} = require('../gyra/descriptors');

const processContactInformation = item => {
    if (!item) {
        item = {}
    }
    const processed = {
        name: item.name ? item.name : null,
        email: item.email ? item.email : null,
        phone: item.phone ? item.phone : null,
    }
    return processed
}

const processInternalLawsuit = item => {
    const processed = {
        number: item.number,
        attached_numbers: item.attached_numbers,
        court: item.court,
        city: item.city,
        province: item.province,
        account: item.account,
        case: item.case,
        ccb: item.ccb,
        cnpj: item.cnpj,
        company_name: item.company_name,
        business_number: item.business_number,
        client_number: item.client_number,
        portfolio: item.portfolio ? item.portfolio.trim() : item.portfolio,
        fee_value: item.fee_value ? helpers.extractDigits(item.fee_value) : 0,
        expires: new Date().toISOString(),
        lawsuit_value: item.lawsuit_value_raw ? item.lawsuit_value_raw : item.lawsuit_value,    
        status: item.status ? item.status : ''
    }

    if (item.office && item.office.id) {
        processed.office = item.office.id
    } else if (item.office) {
        processed.office = item.office
    }
    return processed
}

const processLegalLawsuit = item => {
    const processed = {
        number: item.number,
        attached_numbers: item.attached_numbers,
        court: item.court,
        city: item.city,
        province: item.province,
        status: item.status
    }
    return processed
}

const processOffice = item => {
    const processed = {
        name: item.name,
        email: item.email ? item.email.trim() : null,
        phone: item.phone ? item.phone : null,
    }
    return processed
}

const processCost = item => {
    const processed = {
        refcode: item.refcode ? item.refcode : item.refcode,
        cost_type: item.cost_type ? item.cost_type : item.cost_type,
        status: item.status ? item.status : item.status,
        value: item.value_raw ? item.value_raw : item.value,
    }
    if (item.billet) {
        processed.billet = item.billet
        processed.billet.uploaded_by = processContactInformation(processed.billet.uploaded_by)
        delete processed.billet.filepath
        delete processed.billet.created
        delete processed.billet.modified
    }
    if (item.receipt) {
        processed.receipt = item.receipt
        processed.receipt.uploaded_by = processContactInformation(processed.receipt.uploaded_by)
        delete processed.receipt.filepath
        delete processed.receipt.created
        delete processed.receipt.modified
    }
    if (item.transfer_receipt) {
        processed.transfer_receipt = item.transfer_receipt
        processed.transfer_receipt.uploaded_by = processContactInformation(processed.transfer_receipt.uploaded_by)
        delete processed.transfer_receipt.filepath
        delete processed.transfer_receipt.created
        delete processed.transfer_receipt.modified
    }
    return processed
}

const processSeizedProperty = item => {
    const processed = {
        refcode: item.refcode ? item.refcode : item.refcode,
        property_type: item.property_type ? item.property_type : item.property_type,
        status: item.status ? item.status : item.status,
        value: item.value ? item.value : item.value,
        description: item.description ? item.description.trim() : item.description,
        sender: item.sender ? item.sender : item.sender,
        benefit: item.benefit ? item.benefit : item.benefit,
        seized: item.seized ? new Date(item.seized).toISOString() : new Date().toISOString(),    
    }
    return processed
}

const processPayment = item => {
    const processed = {
        refcode: item.refcode,
    }
    return processed
}

const processComment = item => {
    const processed = {
        comment: item.comment,
    }
    return processed
}

const commentModel = () => {
    return {
        comment: ''
    }
}

const internalLawsuitModel = () => {
    return {
        'office': '',
        'cnpj': '',
        'company_name': '',
        'business_number': null,
        'ccb': '',
        'client_number': null,
        'portfolio': '',
        'fee_value': null,
        'expires': new Date().toISOString().substring(0, 10),
        'status': '',
        'lawsuit_value': ''
    }
}

const legalLawsuitModel = () => {
    return {
        id: '',
        account: '',
        case: '',
        comments: '',
        costs: '',
        events: '',
        cnpj: '',
        lawsuit_value: '',
        company_name: '',
        number: '',
        court: '',
        city: '',
        province: '',
        status: '',
        created_raw: '',
        created: '',
        modified_raw: '',
        modified: '',
        review: '',
    }    
}

const costModel = () => {
    return {
      'description': '',
      'value': null,
      'status': '',
      'cost_type': '',
      'received': new Date().toISOString().substring(0, 10),
    }
}

const seizedPropertyModel = () => {
    return {
        property_type: '',
        status: '',
        refcode: '',
        value: '',
        description: '',
        seized_raw: '',
        seized: '',
    }
}

const officeModel = () => {
    return {
        id: '',
        name: '',
        email: '',
        phone: '',
        lawsuits: '',
        created_raw: '',
        created: '',
        modified_raw: '',
        modified: '',
    }
}

const displayInternalLawsuit = (item) => {
    const display = {
        id: item.id,
        office: item.office ? displayOffice(item.office) : null,
        comments: item.comments ? item.comments.map(displayComment).reverse() : null,
        costs: item.costs ? item.costs.map(displayCost) : null,
        seized_properties: item.seized_properties ? item.seized_properties.map(displaySeizedProperty) : null,
        history: item.history ? item.history.map(displayHistory).reverse() : null,
        events: item.events ? item.events.map(displayActivity) : null,
        status: item.status,
        ccb: item.ccb,
        cnpj: item.cnpj,
        case: item.case,
        number: item.number ? item.number : '',
        court: item.court ? item.court : '',
        city: item.city ? item.city : '',
        province: item.province ? item.province : '',
        company_name: item.company_name,
        business_number: item.business_number,
        client_number: item.client_number,
        portfolio: item.portfolio,
        fee_value: item.fee_value ? item.fee_value + '%' :  '',
        expires_raw: item.expires,
        expires: helpers.formatDate(item.expires),
        lawsuit_value_raw: item.lawsuit_value,
        lawsuit_value: helpers.formatMoney(item.lawsuit_value),    
        created_raw: item.created,
        created: helpers.timeSince(item.created),
        modified_raw: item.modified,
        modified: helpers.timeSince(item.modified),
        review: item.review ? displayFile(item.review) : item.review,
    }
    if (item.kit && item.kit.length > 0) {
        display.kit = item.kit.map(displayFile)
    }
    if (item.reviews && item.reviews.length > 0) {
        display.reviews = item.reviews.map(displayFile)
    }
    display.unpaidCosts = false
    if (item.costs && item.costs.length > 0) {
        const hasCosts = item.costs.filter(i => {return i.status === 'unpaid'})
        if (hasCosts.length > 0) {
            display.unpaidCosts = true
        }
    }
    return display
}

const displayInternalLawsuitForm = (item) => {
    const display = {
        id: item.id,
        office: item.office ? item.office.id : null,
        comments: item.comments ? item.comments.map(displayComment) : null,
        costs: item.costs ? item.costs.map(displayCost) : null,
        seized_properties: item.seized_properties ? item.seized_properties.map(displaySeizedProperty) : null,
        events: item.events ? item.events.map(displayActivity) : null,
        account: item.account,
        case: item.case,
        status: item.status,
        ccb: item.ccb,
        cnpj: item.cnpj,
        company_name: item.company_name,
        business_number: item.business_number,
        client_number: item.client_number,
        portfolio: item.portfolio,
        fee_value: item.fee_value,
        expires_raw: item.expires,
        expires: item.expires ? item.expires.substring(0, 10) : new Date().toISOString().substring(0, 10),
        lawsuit_value: item.lawsuit_value,
        number: item.number ? item.number : '',
        court: item.court ? item.court : '',
        city: item.city ? item.city : '',
        province: item.province ? item.province : '',
    }

    if (item.kit && item.kit.length > 0) {
        display.kit = item.kit.map(displayFile)
    }
    if (item.reviews && item.reviews.length > 0) {
        display.reviews = item.reviews.map(displayFile)
    }
    return display
}

const displayLegalLawsuit = (item) => {
    const display = {
        id: item.id,
        account: item.account,
        ccb: item.ccb,
        client_number: item.client_number,
        case: item.case,
        comments: item.comments ? item.comments.map(displayComment).reverse() : null,
        costs: item.costs ? item.costs.map(displayCost) : null,
        seized_properties: item.seized_properties ? item.seized_properties.map(displaySeizedProperty) : null,
        history: item.history ? item.history.map(displayHistory).reverse() : null,
        events: item.events ? item.events.map(displayActivity) : null,
        cnpj: item.cnpj,
        lawsuit_value: item.lawsuit_value ? helpers.formatMoney(item.lawsuit_value) : '',
        company_name: item.company_name ? item.company_name : '',
        number: item.number ? item.number : '',
        court: item.court ? item.court : '',
        city: item.city ? item.city : '',
        province: item.province ? item.province : '',
        status: item.status,
        created_raw: item.created,
        created: helpers.timeSince(item.created),
        modified_raw: item.modified,
        modified: helpers.timeSince(item.modified),
        review: item.review ? displayFile(item.review) : item.review,
    }
    const isNew = helpers.lessThanXHours(item.created, 24 * 7)
    display.isNew = false
    if (isNew) {
        display.isNew = true
    }
    if (item.kit && item.kit.length > 0) {
        display.kit = item.kit.map(displayFile)
    }
    if (item.reviews && item.reviews.length > 0) {
        display.reviews = item.reviews.map(displayFile)
    }
    console.log('CCB', item.ccb)
    return display
}

const displayLegalLawsuitForm = (item) => {
    const attached_numbers = ['', '']
    const display = {
        id: item.id,
        account: item.account,
        ccb: item.ccb,
        client_number: item.client_number,
        case: item.case,
        cnpj: item.cnpj,
        lawsuit_value: item.lawsuit_value ? helpers.formatMoney(item.lawsuit_value) : '',
        company_name: item.company_name ? item.company_name : '',
        status: item.status,
        court: item.court,
        number: item.number,
        attached_numbers: attached_numbers,
        province: item.province,
        city: item.city,
        costs: item.costs ? item.costs.map(displayCost) : null,
        seized_properties: item.seized_properties ? item.seized_properties.map(displaySeizedProperty) : null,
    }
    return display
}

const displayInternalLawsuitTableHeaders = () => {
    const display = [
        { text: 'Caso', value: 'case' },
        { text: 'Empresa', value: 'name' },
        { text: 'Escritório', value: 'office', filterable: false },
        { text: 'Valor do Caso', filterable: false  },
        { text: 'Portfolio', value: 'portfolio', filterable: false },
        { text: 'Status', value: 'status', filterable: false },
        { text: 'Custas' , value: 'unpaidCosts', filterable: false},
        { text: 'Criado', value: 'created_raw', filterable: false },
        { text: 'Modificado',  value: 'modified_raw', filterable: false },
        { text: 'Action',  sortable: false }
    ]
    return display
}
const displayInternalLawsuitListItem = (item) => {
    const display = {
        id: item.id,
        cnpj: item.cnpj,
        costs: item.costs ? item.costs.map(displayCost) : null,
        seized_properties: item.seized_properties ? item.seized_properties.map(displaySeizedProperty) : null,
        office: item.office,
        case: '<h2>' + item.case + '</h2>',
        portfolio: item.portfolio,
        name: '<h3>' + item.company_name + '</h3><h4> ' + item.account + ' </h4>',
        lawsuit_value: helpers.formatMoney(item.lawsuit_value),
        status: item.status,
        company_name: item.company_name,
        ccb: item.ccb,
        created_raw: new Date(item.created).getTime(),
        created: helpers.timeSince(item.created) + ' <br> ' + helpers.formatDate(item.created),
        modified_raw: new Date(item.modified).getTime(),
        modified: helpers.timeSince(item.modified) + ' <br> ' + helpers.formatDate(item.modified),
    }
    display.unpaidCosts = false
    const hasCosts = item.costs.filter(i => {return i.status === 'unpaid'})
    if (hasCosts.length > 0) {
        display.unpaidCosts = true
    }
    return display
}

const displayLegalLawsuitTableHeaders = () => {
    const display = [
        { text: 'Caso', value: 'case' },
        { text: 'Empresa', value: 'name' },
        { text: 'Tribunal', value: 'court_info' , filterable: false},
        { text: 'Caso', value: 'numbers' },
        { text: 'Status', value: 'status', filterable: false },
        { text: 'Custas', value: 'unpaidCosts', filterable: false },
        { text: 'Criado', value: 'created_raw', filterable: false },
        { text: 'Modificado',  value: 'modified_raw', filterable: false },
        { text: 'Action',  sortable: false }
    ]
    return display
}

const displayLawsuitNumbers = item => {
    let numbers = 'apensos:<br>'
    if (item.attached_numbers && item.attached_numbers.filter(i => {return i}).length == 0) {
        return '<strong>' + item.number ? item.number : '' + '</strong>'
    } 
    item.attached_numbers.map(i => {
        numbers += i + '<br>'
    })
    return '<strong>' + item.number + '</strong><br>' + numbers
}

const displayLegalLawsuitListItem = item => {
    const display = {
        id: item.id,
        case: '<h2>' + item.case + '</h2>',
        name: '<h3>' + item.company_name ? item.company_name : '' + '</h3><h4> ' + item.account ? item.account : '' + ' </h4><h2>' + item.cnpj ? item.cnpj : '' + '</h2>',
        lawsuit_value: item.lawsuit_value,
        numbers: displayLawsuitNumbers(item),
        court_info: '<h3>' + item.court ? item.court : '' + '</h3><br><h4>' + item.city ? item.city : '' + ' / ' + item.province ? item.province : '' + '</h4>',
        status: item.status,
        created_raw: item.created,
        created: helpers.timeSince(item.created) + ' <br> ' + helpers.formatDate(item.created),
        modified_raw: item.modified,
        modified: helpers.timeSince(item.modified) + ' <br> ' + helpers.formatDate(item.modified),
    }
    display.unpaidCosts = false
    const hasCosts = item.costs.filter(i => {return i.status === 'unpaid'})
    if (hasCosts.length > 0) {
        display.unpaidCosts = true
    }
    if (item.costs) {
        display.costs = item.costs        
    }
    return display
}

const displayCostReportTableHeaders = () => {
    const display = [
        { text: 'Recebido', value: 'received' },
        { text: 'Portfolio', value: 'portfolio' },
        { text: 'Caso', value: 'case', filterable: false },
        { text: 'Referente à', value: 'type', filterable: false  },
        { text: 'Valor', value: 'value', filterable: false },
        { text: 'Status', value: 'status', filterable: false },
        { text: 'Empresa' , value: 'company_name', filterable: false},
        { text: 'Escritório', value: 'office', filterable: false },
        { text: 'CCB',  value: 'ccb', filterable: false },
        { text: 'CNPJ',  value: 'cnpj', filterable: false },
        { text: 'Action',  sortable: false }
    ]
    return display    
}

const displayCostReportTable = items => {
    let data = []
    let c = 0
    let d = 0
    for (let index = 0; index < items.length; index++) {
        const lawsuit = items[index]
        if (!lawsuit.costs) {
            continue
        }
        for (let ii = 0; ii < lawsuit.costs.length; ii++) {
            const costs = lawsuit.costs
            const office = lawsuit.office
            const item = {
                lawsuit_id: lawsuit.id,
                portfolio: lawsuit.portfolio,
                case: lawsuit.case,
                type: costs[ii].cost_type,
                value: helpers.formatMoney(costs[ii].value),
                status: costs[ii].status,
                company_name: lawsuit.company_name,
                office: office,
                ccb: lawsuit.ccb,
                cnpj: lawsuit.cnpj,
                refcode: costs[ii].refcode,
                billet: costs[ii].billet,
                receipt: costs[ii].receipt,
                transfer_receipt: costs[ii].transfer_receipt,
            }
            if (!item.paid) {
                item.received = helpers.formatDate(costs[ii].paid_raw)
            } else {
                item.modified = helpers.formatDate(costs[ii].modified_raw)
            }

            if (item) {
                c = c + 1
                data.push(item)
            }
            d = d + 1
        }
    }
    return data
}

const displaySeizedPropertyReportTableHeaders = () => {
    const display = [
        { text: 'Recebido', value: 'received' },
        { text: 'Portfolio', value: 'portfolio' },
        { text: 'Caso', value: 'case', filterable: false },
        { text: 'Referente à', value: 'type', filterable: false  },
        { text: 'Valor', value: 'value', filterable: false },
        { text: 'Status', value: 'status', filterable: false },
        { text: 'Empresa' , value: 'company_name', filterable: false},
        { text: 'Escritório', value: 'office', filterable: false },
        { text: 'CCB',  value: 'ccb', filterable: false },
        { text: 'CNPJ',  value: 'cnpj', filterable: false },
        { text: 'Action',  sortable: false }
    ]
    return display    
}

const displaySeizedPropertyReportTable = items => {
    let data = []
    let c = 0
    let d = 0
    for (let index = 0; index < items.length; index++) {
        const lawsuit = items[index]
        if (!lawsuit.seized_properties) {
            continue
        }
        for (let ii = 0; ii < lawsuit.seized_properties.length; ii++) {
            const seized_properties = lawsuit.seized_properties
            const office = lawsuit.office
            const item = {
                lawsuit_id: lawsuit.id,
                received: helpers.formatDate(seized_properties[ii].created),
                portfolio: lawsuit.portfolio,
                case: lawsuit.case,
                type: seized_properties[ii].cost_type,
                value: helpers.formatMoney(seized_properties[ii].value),
                status: seized_properties[ii].status,
                company_name: lawsuit.company_name,
                office: office,
                ccb: lawsuit.ccb,
                cnpj: lawsuit.cnpj,
                refcode: seized_properties[ii].refcode
            }
            if (item) {
                c = c + 1
                console.log(c)
                data.push(item)
            }
            d = d + 1
            console.log(d)
        }
    }
    return data
}

const displayOffice = item => {
    const display = {
        id: item.id,
        name: item.name,
        email: item.email,
        phone: item.phone,
        lawsuits:  item.lawsuits ? item.lawsuits.map(displayInternalLawsuitListItem) : [],
        created_raw: item.created,
        created: helpers.timeSince(item.created),
        modified_raw: item.modified,
        modified: helpers.timeSince(item.modified),
    }
    return display
}

const displayCost = (item) => {
    const display = {
        cost_type: item.cost_type,
        status: item.status,
        refcode: item.refcode,
        value_raw: item.value,
        value:helpers.formatMoney(item.value),
        description: item.description,
        sender: item.sender,
        benefit: item.benefit,
        received_raw: item.received,
        received: helpers.timeSince(item.received),
        expires_raw: item.expires,
        expires: helpers.timeSince(item.expires),
        created_raw: item.created,
        created: helpers.timeSince(item.created),
        paid_raw: item.paid,
        paid: helpers.timeSince(item.paid),
        receipt: item.receipt ? displayFile(item.receipt) : '',
        transfer_receipt: item.transfer_receipt ? displayFile(item.transfer_receipt) : '',
        billet: item.billet ? displayFile(item.billet) : ''
    }
    return display
}

const displayCostForm = item => {
    const display = {
        cost_type: item.cost_type,
        status: item.status,
        refcode: item.refcode,
        value: item.value,
        description: item.description,
        sender: item.sender,
        benefit: item.benefit,
        received_raw: item.received,
        received: item.received ? item.received.substring(0, 10) : new Date().toISOString().substring(0, 10),
        expires_raw: item.expires,
        expires: item.expires ? item.expires.substring(0, 10) : new Date().toISOString().substring(0, 10),
        paid_raw: item.paid,
        paid: item.paid ? item.paid.substring(0, 10) : new Date().toISOString().substring(0, 10),
    }
    if (item.transfer_receipt) {
        display.transfer_receipt = item.transfer_receipt ? displayFile(item.transfer_receipt) : item.transfer_receipt
    }
    if (item.receipt) {
        display.receipt = item.receipt ? displayFile(item.receipt) : item.receipt
    }
    if (item.billet) {
        display.billet = item.billet ? displayFile(item.billet) : item.billet
    }
    return display
}

const displaySeizedProperty = (item) => {
    const display = {
        property_type: item.property_type,
        status: item.status,
        refcode: item.refcode,
        value_raw: item.value,
        value: helpers.formatMoney(item.value),
        description: item.description,
        seized_raw: item.received,
        seized: helpers.timeSince(item.received),
    }
    return display
}

const displaySeizedPropertyForm = item => {
    const display = {
        cost_type: item.property_type,
        status: item.status,
        refcode: item.refcode,
        value: item.value,
        description: item.description,
        seized_raw: item.seized,
        seized: item.seized ? item.seized.substring(0, 10) : new Date().toISOString().substring(0, 10),
    }
    return display
}

setLocale({
  mixed: {
    default: 'Não é válido',
  },
  number: {
    default: 'Não é um número válido',
    min: 'Deve ser maior que ${min}',
  },
  string: {
    min: 'Deve ser maior que ${min}',
  },
});

let commentSchema = yup.object().shape({
    comment: yup.string().required(),
})

let costSchema = yup.object().shape({
    status: yup.string().required(),
    cost_type: yup.string().required(),
    value: yup.number().required(),
    description: yup.string().nullable(true),
    sender: yup.string().nullable(true),
    benefit: yup.string().nullable(true),
    expires: yup.string().nullable(true),
})

let seizedPropertySchema = yup.object().shape({
    status: yup.string().required(),
    property_type: yup.string().required(),
    value: yup.number().required(),
    description: yup.string().nullable(true),
    seized: yup.string().nullable(true),
})

let internalLawsuitSchema = yup.object().shape({
    lawsuit_value: yup.number().required(),
    cnpj: yup.string().required(),
    office: yup.string().required(),
    account: yup.string().nullable(true),
    case: yup.string().nullable(true),
    ccb: yup.string().nullable(true),
    company_name: yup.string().nullable(true),
    business_string: yup.string().nullable(true),
    client_string: yup.string().nullable(true),
    portfolio: yup.string().nullable(true),
    fee_value: yup.number().nullable(true),
    expires : yup.date().nullable(true),
    status: yup.string().nullable(true),
})

let legalLawsuitSchema = yup.object().shape({
    number: yup.string().nullable(true),
    attached_numbers: yup.array().of(yup.string().nullable(true)),
    status: yup.string().nullable(true),
    court: yup.string().nullable(true),
    city: yup.string().nullable(true),
    province: yup.string().nullable(true),
})

let officeSchema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().required(),
    phone: yup.string(),
})



export {
    internalLawsuitModel,
    legalLawsuitModel,
    officeModel,
    costModel,
    seizedPropertyModel,
    commentModel,

    displayInternalLawsuit,
    displayInternalLawsuitTableHeaders,
    displayInternalLawsuitListItem,
    displayInternalLawsuitForm,
    displayLegalLawsuit,
    displayLegalLawsuitTableHeaders,
    displayLegalLawsuitListItem,
    displayLegalLawsuitForm,
    displayOffice,
    displayCost,
    displayCostForm,
    displaySeizedProperty,
    displaySeizedPropertyForm,
    displayComment,

    displayCostReportTableHeaders,
    displayCostReportTable,

    displaySeizedPropertyReportTableHeaders,
    displaySeizedPropertyReportTable,

    displayContactInformation,
    displayFile,


    commentSchema,
    costSchema,
    seizedPropertySchema,
    internalLawsuitSchema,
    legalLawsuitSchema,
    officeSchema,

    processInternalLawsuit,
    processLegalLawsuit,
    processOffice,
    processCost,
    processSeizedProperty,
    processPayment,
    processComment
}
