<template>
  <div v-if="items">
    <p>mostrando {{items.length}} casos</p>

    <v-row>
      <v-col md="4">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Filtro"
          single-line
          hide-details
        ></v-text-field>
      </v-col>

      <v-col md="6">&nbsp;</v-col>
      
      <v-col md="2">&nbsp;</v-col>
      
    </v-row>
    <br>
    <br>

    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
    >
      <template v-slot:item="row">
          <tr>
            <td v-html="row.item.case"></td>
            <td>
              <div v-html="row.item.name + '<h4>' + row.item.cnpj + '</h4>'"></div>
              <p>{{row.item.business_number}}</p>
            </td>
            <td>
              <div v-if="row.item.office">
                <h3>
                  {{row.item.office.name}}
                </h3>
              </div>
            </td>
            <td><h3>{{row.item.lawsuit_value}}</h3></td>
            <td @click="filterTable('portfolio', row.item.portfolio)">
              <status-table-display
                 style="cursor:pointer"
                :dictionaryKey="row.item.portfolio"
              >    
              </status-table-display>
            </td>
            <td @click="filterTable('status', row.item.status)">
              <status-table-display
                style="cursor:pointer"
                :dictionaryKey="row.item.status"
              >
              </status-table-display>
            </td>
            <td>
              <v-avatar v-if='row.item.unpaidCosts' color="red">
                <v-icon mini dark>
                  mdi-cash
                </v-icon>
              </v-avatar>
              <v-avatar v-if='!row.item.unpaidCosts' small color="green">
                <v-icon dark>
                  mdi-cash
                </v-icon>
              </v-avatar>
            </td>
            <td v-html='row.item.created'></td>
            <td v-html='row.item.modified'></td>
            <td>
              <view-button 
                view="small"
                :path="'/lawsuit/view/' + row.item.id"
              ></view-button>

            </td>
          </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import ViewButton from '../../components/ViewButton.vue'
  import StatusTableDisplay from '../../components/StatusTableDisplay.vue'
  const {displayInternalLawsuitTableHeaders} = require('../descriptors.js')
  const {EventBus} = require('../../addons/event-bus.js')

  export default {
    components: {
      ViewButton,
      StatusTableDisplay,
    },
    name: 'LawsuitControlTable',
    props: ['items'],
    data() {
      return {
        search: '',
      }
    },
    computed: {
      headers() {
        return displayInternalLawsuitTableHeaders()
      },
    },
    methods: {
        filterTable(key, value) {
          let filters = {}
          filters[key] = value
          EventBus.$emit('lawsuit-search-submitted', filters)
        },
    }
  }

</script>

<style scoped>
td {
  height:  100px !important;
}
</style>