const helpers = require('../addons/helpers');

const displayInternalLawsuitSpreadsheet = (items, dictionary={}, dateFormat='pt-br', moneyFormat=false) => {
    let data = []
    const headers = [
        'Caso',
        'Nº do Processo',
        'Nº do Cliente',
        'Tribunal',
        'Cidade',
        'Empresa',
        'Nome Cliente',
        'CNPJ',
        'CCB',
        'Valor',
        'Portfolio',
        'Status',
        'Criado',
        'Atualizado',
        'Última Custa'
    ]
    data.push(headers)
    for (let index = 0; index < items.length; index++) {
        const item = items[index]
        const line = [
            item.case,
            item.number,
            item.client_number,
            item.city,
            item.court,
            item.company_name,
            item.account,
            item.cnpj,
            item.ccb,
            moneyFormat ? helpers.formatMoney(item.lawsuit_value) : item.lawsuit_value,
            helpers.fromDictionary(dictionary, item.portfolio),
            helpers.fromDictionary(dictionary, item.status),
            helpers.formatDate(item.created, dateFormat),
            helpers.formatDate(item.modified, dateFormat),
            helpers.formatDate(helpers.getMaxDate(item.costs, 'created')),
        ]
        data.push(line);
    }
    return data
}

const displayInternalLawsuitSpreadsheetRow = (item, dictionary={}, dateFormat='pt-br', moneyFormat=false) => {

    let data = []
    const headers = [
        'Caso',
        'Nº do Processo',
        'Tribunal',
        'Cidade',
        'Empresa',
        'CNPJ',
        'CCB',
        'Valor',
        'Portfolio',
        'Status',
        'Criado',
        'Atualizado',
        'Última Custa'
    ] 
    data.push(headers)
    const line = [
        item.case,
        item.number,
        item.city,
        item.court,
        item.company_name + ' ' + item.account,
        item.cnpj,
        item.ccb,
        moneyFormat ? helpers.formatMoney(item.lawsuit_value) : item.lawsuit_value,
        helpers.fromDictionary(dictionary, item.portfolio),
        helpers.fromDictionary(dictionary, item.status),
        helpers.formatDate(item.created, dateFormat),
        helpers.formatDate(item.modified, dateFormat),
        helpers.formatDate(helpers.getMaxDate(item.costs, 'created')),
    ]
    return line
}

const displayLegalLawsuitSpreadsheet = (items, dictionary={}, dateFormat='pt-br', moneyFormat=false) => {
    let data = []
    const headers = [
        'Caso',
        'Nº do Processo',
        'Tribunal',
        'Cidade',
        'Empresa',
        'CNPJ',
        'CCB',
        'Valor',
        'Portfolio',
        'Status',
        'Criado',
        'Atualizado',
        'Última Custa'
    ] 
    data.push(headers)
    for (let index = 0; index < items.length; index++) {
        const item = items[index]
        const line = [
            item.case,
            item.company_name + ' ' + item.account,
            item.cnpj,
            item.ccb,
            moneyFormat ? helpers.formatMoney(item.lawsuit_value) : item.lawsuit_value,
            helpers.fromDictionary(dictionary, item.portfolio),
            helpers.fromDictionary(dictionary, item.status),
            helpers.formatDate(item.created, dateFormat),
            helpers.formatDate(item.modified, dateFormat),
        ]
        data.push(line);
    }
    return data
}

const displayCostsSpreadsheet = (items, dictionary={}, dateFormat='pt-br', moneyFormat=false) => {
    console.log(dateFormat, moneyFormat)
    let data = []
    const headers = [
        'Recebido',
        'Portfolio',
        'Caso',
        'Referente à',
        'Valor',
        'Status',
        'Empresa',
        'Escritório',
        'CNPJ',
        'CCB',
    ]
    data.push(headers)
    for (let index = 0; index < items.length; index++) {
        let line
        const lawsuit = items[index]
        for (let ii = 0; ii < lawsuit.costs.length; ii++) {
            const costs = lawsuit.costs
            const office = lawsuit.office
            let office_name = ''
            if (office) {
                office_name = office.name
            }

            const created = costs[ii].created
            const modified = costs[ii].modified
            const received = costs[ii].received
            const paid = costs[ii].paid
            let payDate
            if (paid) {
                payDate = paid
            } else if (received) {
                payDate = received
            } else if (modified) {
                payDate = modified
            } else if (created) {
                payDate = created
            }

            line = [
                helpers.formatDate(payDate, dateFormat),
                helpers.fromDictionary(dictionary, lawsuit.portfolio),
                lawsuit.case,
                helpers.fromDictionary(dictionary, costs[ii].cost_type),
                costs[ii].value,
                helpers.fromDictionary(dictionary, costs[ii].status),
                lawsuit.company_name + ' ' + lawsuit.account,
                office_name,
                lawsuit.cnpj,
                lawsuit.ccb,
            ]
            data.push(line)
        }
    }
    return data
}

const displayLawsuitCostsSpreadsheet = (item, dictionary={}, dateFormat='pt-br', moneyFormat=false) => {
    console.log(dateFormat, moneyFormat)
    let data = []
    const headers = [
        'Empresa',
        'Escritório',
        'Caso',
        'CNPJ',
        'CCB',
        'Referente à',
        'Valor',
        'Status',
        'Recebido em'
    ] 
    data.push(headers)
    console.log(dateFormat, moneyFormat)
    for (let index = 0; index < item.costs.length; index++) {
        const cost = item.costs[index]
        const line = [
            item.office && item.office.name ? item.office.name : '',
            item.company_name + ' ' + item.account,
            item.case,
            item.cnpj,
            item.ccb,
            cost.description,
            cost.value,
            helpers.fromDictionary(dictionary, cost.status),
            helpers.formatDate(cost.received, dateFormat)
        ]
        data.push(line);
    }
    return data
}

const displayPropertiesSpreadsheet = (items, dictionary={}, dateFormat='pt-br', moneyFormat=false) => {
    console.log(dateFormat, moneyFormat)
    let data = []
    const headers = [
        'Recebido',
        'Portfolio',
        'Caso',
        'Referente à',
        'Valor',
        'Status',
        'Empresa',
        'Escritório',
        'CCB',
        'CNPJ',
        'Recebido em'
    ]
    data.push(headers)
    for (let index = 0; index < items.length; index++) {
        let line
        const lawsuit = items[index]
        for (let ii = 0; ii < lawsuit.costs.length; ii++) {
            const costs = lawsuit.costs
            const office = lawsuit.office
            let office_name = ''
            if (office) {
                office_name = office.name
            }
            line = [
                helpers.formatDate(costs[ii].created),
                helpers.fromDictionary(dictionary, lawsuit.portfolio),
                lawsuit.case,
                helpers.fromDictionary(dictionary, costs[ii].cost_type),
                costs[ii].value,
                helpers.fromDictionary(dictionary, costs[ii].status),
                lawsuit.company_name + ' ' + lawsuit.account,
                office_name,
                lawsuit.ccb,
                lawsuit.cnpj,
                helpers.formatDate(costs[ii].received, dateFormat)
            ]
            data.push(line)
        }
    }
    return data
}

const displayLawsuitPropertiesSpreadsheet = (item, dictionary={}, dateFormat='pt-br', moneyFormat=false) => {
    console.log(dateFormat, moneyFormat)
    let data = []
    const headers = [
        'Empresa',
        'Escritório',
        'Caso',
        'Referente à',
        'Valor',
        'Status',
        'Recebido em'
    ] 
    data.push(headers)
    for (let index = 0; index < item.costs.length; index++) {
        const cost = item.costs[index]
        const line = [
            item.company_name + ' ' + item.account,
            item.office && item.office.name ? item.office.name : '',
            item.case,
            cost.description,
            cost.value,
            helpers.fromDictionary(dictionary, cost.status),
            helpers.formatDate(item.received, dateFormat)
        ]
        data.push(line);
    }
    return data
}

const displayLawsuitDetailReportSpreadsheet = (item, dictionary={}, dateFormat='pt-br', moneyFormat=false) => {
    console.log(dateFormat, moneyFormat)
    const lawsuit = displayInternalLawsuitSpreadsheetRow(item, dictionary, dateFormat, moneyFormat) 
    const costs = displayLawsuitCostsSpreadsheet(item, dictionary, dateFormat, moneyFormat) 
    const properties = displayPropertiesSpreadsheet(item, dictionary, dateFormat, moneyFormat) 
    let data = []
    return data
        .concat(['DETALHE DO PROCESSO'])
        .concat(lawsuit)
        .concat([])
        .concat(['RELATÓRIO DE CUSTAS'])
        .concat(costs)
        .concat([])
        .concat(['RELATÓRIO DE BENS'])
        .concat(properties)
}


const displayFullLawsuitReportSpreadsheet = (items, dictionary={}, dateFormat='pt-br', moneyFormat=false) => {
    console.log(dateFormat, moneyFormat)
    const books = []
    const lawsuits = displayInternalLawsuitSpreadsheet(items, dictionary, dateFormat, moneyFormat) 
    const costs = displayCostsSpreadsheet(items, dictionary, dateFormat, moneyFormat) 
    const properties = displayPropertiesSpreadsheet(items, dictionary, dateFormat, moneyFormat) 

    books.append({"Casos": lawsuits})
    books.append({"Custas": costs})
    books.append({"Bens": properties})

    for (var i = items.length - 1; i >= 0; i--) {
        const key = 'CASO ' + items[i].case
        let detail = {}
        detail[key] = displayLawsuitDetailReportSpreadsheet(items[i])
        books.append(detail)
    }
    return books
}


export {
    displayLegalLawsuitSpreadsheet,
    displayLawsuitDetailReportSpreadsheet,
    displayFullLawsuitReportSpreadsheet,
    displayInternalLawsuitSpreadsheet,
    displayCostsSpreadsheet,
    displayPropertiesSpreadsheet,
    displayLawsuitCostsSpreadsheet,
    displayLawsuitPropertiesSpreadsheet,
}
