<template>
    <v-row>
      <v-col md=6>


 <v-timeline
      dense
      clipped
    >
      <v-timeline-item
        fill-dot
        class="white--text mb-12"
        color="blue"
        large
      >
        <template v-slot:icon>
          <v-icon dark>
            mdi-comment-plus
          </v-icon>
        </template>
        <v-textarea
          v-model="input"
          hide-details
          flat
          label="Escreva uma observação"
          solo
          @keydown.enter="comment"
        >
        </v-textarea>
        <save-button @save="comment" view="big"></save-button>
      </v-timeline-item>


      <v-timeline-item
        v-for="event in item.comments"
        v-bind:key="event.refcode"
        class="mb-4"
        color="grey"
        icon-color="grey lighten-2"
        small
      >
        <v-row justify="space-between">
          <v-col cols="7">
            {{event.comment}}
          </v-col>
          <v-col
            class="text-right"
            cols="5"
          >
            {{event.when}}
          </v-col>
        </v-row>
      </v-timeline-item>

    </v-timeline>

      </v-col>


      <v-col md=3>
        <v-card>
            <v-row>
              <v-col md='9'>
                <h2 class="title">Atividade</h2>
              </v-col>
            </v-row>

        </v-card>
        <v-timeline v-if="item.events" dense align-top>
            <v-timeline-item
              v-for="event in item.events"
              v-bind:key="event.refcode"
              :color="dictionary[event.value] && dictionary[event.value].color ? dictionary[event.value].color : 'blue'"
              :icon="setIcon(event)"
            >
            <p>
              <span v-if="dictionary[event.value]">{{dictionary[event.value].name}}</span>
              <span v-if="!dictionary[event.value]">{{event.value}}</span>              
              
              <br>
            <small>{{event.time}}</small>
            </p>
          </v-timeline-item>
        </v-timeline>
      </v-col>


      <v-col md=3>
        <v-card>
            <v-row>
              <v-col md='9'>
                <h2 class="title">Histórico</h2>
              </v-col>
            </v-row>
        </v-card>
        
        <v-timeline v-if="item.history" dense align-top>
            <v-timeline-item
              v-for="event in item.history"
              v-bind:key="event.refcode"
              :color="dictionary[event.value] && dictionary[event.value].color ? dictionary[event.value].color : 'blue'"
              :icon="setIcon(event)"
            >
            <p>
              <span v-if="event.old && event.new">
                Status: {{dictionary[event.old] ? dictionary[event.old].name : event.old}} para {{dictionary[event.new] ? dictionary[event.new].name : event.new}}
              </span>
              <br>
              <small>{{event.when}}</small>
            </p>
          </v-timeline-item>
        </v-timeline>
      </v-col>



    </v-row>
</template>
<script>
    import SaveButton from '../../components/SaveButton'
    const {EventBus} = require('../../addons/event-bus.js')

    export default {
        components: {
          SaveButton
        },
        data() {
          return {
            input: ''
          }
        },
        props: ['item'],
        computed: {
            dictionary: function() {
                return this.$store.getters.dictionary
            }
        },
        methods: {
          comment() {
            const comment = {comment:this.input}
            EventBus.$emit('comment-submitted', {comment})
          },
          setIcon(event) {
            if (event.description) {
              return this.dictionary[event.new] && this.dictionary[event.new].icon ? this.dictionary[event.new].icon : ''
            }
            return this.dictionary[event.value] && this.dictionary[event.value].icon ? this.dictionary[event.value].icon : ''
          }
        }
    }
</script>