<template>
  <v-container fluid>
  <v-row>
    <v-col
      md=4
      sm=12
      xs=12
    >
    <v-card
        class="mx-auto"
      >
        <v-img
          alt=""
          style="display:none"
          class="shrink mr-2"
          contain
          :src="'/static/illustrations/' + image + '.svg'"
          width="100%"
          icon
        />
        <v-card-title>
            <h1 class='title'>Importar Casos</h1>
        </v-card-title>
        <v-card-text>
          a exportação do arquivo deve ser feita em formato CSV<br>
          a planilha não pode ter o cabeçalho com os nomes das colunas<br>
          as colunas não podem conter vírgula<br>
        </v-card-text>
    </v-card>
    </v-col>

    <v-col
    >
      <div v-if="!$store.state.loading">
        <v-row no-gutters justify="center" align="center">

          <v-col cols="4">
            <v-select
              id="officeInput"
              name="office"
              label="Escritório"
              v-model="office"
              item-text="name"
              item-value="id"
              :items="offices"
            ></v-select>
          </v-col>

          <v-col cols="8">
            <v-file-input
              show-size
              label="Arquivo"
              @change="selectFile"
            ></v-file-input>
          </v-col>

          <v-col cols="4" class="pl-2">
            <v-btn color="success" dark small @click="upload">
              Upload
              <v-icon right dark>mdi-cloud-upload</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-alert v-if="message" border="left" color="blue-grey" dark>
          {{ message }}
        </v-alert>

        <v-card v-if="fileInfos.length > 0" class="mx-auto">
          <v-list>
            <v-subheader>List of Files</v-subheader>
            <v-list-item-group color="primary">
              <v-list-item v-for="(file, index) in fileInfos" :key="index">
                <a :href="file.url">{{ file.name }}</a>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </div>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
export default {
  name: "AddLawsuitFile",
  data() {
    return {
      office: '',
      image: '',
      currentFile: undefined,
      message: "",
      fileInfos: [],
      definitions: {
        'kit': {
          title: 'Adicionar Documento',
        },
        'reviews': {
          title: 'Adicionar Minuta',
        },
      }
    }
  },
  computed: {
    offices: function() {
      return this.$store.getters.officeSelectList
    },
    role() {
      if (this.roles.map(i => {return i.value}).includes('lawyer')) {
        return 'legal'
      }
      return 'internal'
    },
    roles() {
      return this.$store.getters.profileRoles
    },
    title() {
      return this.definition && this.definition.title ? this.definition.title : ''
    },
    definition() {
      return this.definitions[this.$route.params.file] ? this.definitions[this.$route.params.file] : {}
    },
  },
  mounted() {
      return this.$store.dispatch('getEntities', {prefix:'office'}).then(r => {
        this.$store.commit('setReady')
        return r
      })
  },
  methods: {
    selectFile(file) {
      this.currentFile = file
    }, 
    upload() {
      if (!this.currentFile) {
        this.message = "Selecione um arquivo"
        return
      }

      this.message = "";
      const file = this.currentFile
      const redir = '/lawsuit/'
      let action = 'uploadLawsuitImport'
      this.$store
        .dispatch(action, {file, office: this.office})
        .then((r) => {
          this.$store.commit('setReady')
          this.$router.push({path: redir})
          return r
        })
    },         
  }
};
</script>