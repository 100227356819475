<template>
  <div  v-if="item">
    <v-row>
      
      <v-col md=12>

       
        <v-row>

          <v-col md="4">
            <v-card>

    <v-card-actions v-if="view === 'view' || (view === 'edit' && !isInternal)">
      <div class="text--primary">
        Informações da Empresa
      </div>
      <v-spacer></v-spacer>
              <span v-if="isInternal">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        id="editButtonSm"
                        class="float-right"
                        fab
                        dark
                        x-small
                        color="default"
                        v-bind="attrs"
                        v-on="on"
                        link :to="'/lawsuit/edit/' + $route.params.id"
                      >
                        <v-icon dark>
                          mdi-pencil
                        </v-icon>
                      </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </span>
    </v-card-actions>

    <v-card-text v-if="view === 'view' || (view === 'edit' && !isInternal)">
  

            <div v-if="isInternal && (!item.office || !item.company_name || !item.lawsuit_value || !item.ccb || !item.cnpj || !item.client_number)">
              <v-alert
                border="right"
                dark
                dense
                color="warning"
              >Informações faltando
              </v-alert>
        
            </div>

            <p class="headline">
              {{item.company_name}}
            </p>
            <p class="title">
              {{item.account}}
            </p>
            <p class="title">
              {{item.lawsuit_value}}
            </p>

              <p>CNPJ {{item.cnpj}} </p>
              <p>CCB {{item.ccb}}</p>
              <p>Nº do Cliente {{item.client_number}}</p>

    </v-card-text>

    <v-card-text v-if="view === 'create' || (view === 'edit' && isInternal)">
              <lawsuit-control-form
                :roles="roles"
                :offices="offices"
                :portfolios="portfolios"
                :lawsuitStatus="lawsuitStatus"
                :item='item'>
              </lawsuit-control-form>

    </v-card-text>

            </v-card>

          </v-col>

        <v-col md="4">
            <v-card>
              <v-card-actions v-if="view === 'view'">
                <div class="text--primary">
                  Informações do Caso
                </div>
                <v-spacer></v-spacer>
                    <span v-if="!isInternal">
              
                       <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              id="editButtonSm"
                              class="float-right"
                              fab
                              dark
                              x-small
                              color="default"
                              v-bind="attrs"
                              v-on="on"
                              link :to="'/lawsuit/edit/' + $route.params.id"
                            >
                              <v-icon dark>
                                mdi-pencil
                              </v-icon>
                            </v-btn>
                        </template>
                        <span>Editar</span>
                      </v-tooltip>
                    </span>
              </v-card-actions>



              <v-card-text v-if="view === 'view'">
            <div v-if="!isInternal && (!item.number || !item.court || !item.province || !item.city)">
              <v-alert
                border="right"
                dark
                dense
                color="warning"
              >Faltam informações
              </v-alert>
            </div>

            <p>Nº do Processo {{item.number}}</p>
            <p>Tribunal/Vara {{item.court}}</p>
            <p>UF {{item.province}}</p>
            <p>Cidade {{item.city}}</p>
              </v-card-text>


              <v-card-text v-if="view === 'create' || view === 'edit'">
                <lawsuit-form
                  :legalStatus="lawsuitStatus"
                  :roles="roles"
                  :provinces="provinces"
                  :item='item'>
                </lawsuit-form>
              </v-card-text>
            </v-card>

          </v-col>

          

          <v-col md="4">
            <v-card
              v-if="view === 'view' || view === 'edit'"
              color="#ddd"
            >
              <v-card-text>
                      <lawsuit-status-display :dictionaryKey="item.status"></lawsuit-status-display>
              </v-card-text>

              <v-card-actions>
                <v-list-item class="grow">
                  <v-row
                    align="center"
                    justify="end"
                  >
                    <v-icon class="mr-1">
                      mdi-file-document
                    </v-icon>
                    <span class="subheading mr-2">{{item.kit ? item.kit.length : 0}}</span>
                    <span class="mr-1">·</span>
                    <v-icon class="mr-1">
                      mdi-cash
                    </v-icon>
                    <span class="subheading">{{item.costs ? item.costs.length : 0}}</span>
                    <span class="mr-1">·</span>
                    <v-icon class="mr-1">
                      mdi-car
                    </v-icon>
                    <span class="subheading">{{item.seized_properties ? item.seized_properties.length : 0}}</span>
                  </v-row>
                </v-list-item>
              </v-card-actions>
            </v-card>


          </v-col>

        </v-row>




      </v-col>




    </v-row>
  </div>
</template>

<script>
  import LawsuitStatusDisplay from './LawsuitStatusDisplay.vue'
  import LawsuitControlForm from './LawsuitControlForm.vue'
  import LawsuitForm from './LawsuitForm.vue'

  export default {
    name: 'LawsuitControlDetail',
    components: {
      LawsuitStatusDisplay,
      LawsuitControlForm,
      LawsuitForm
    },
    props: ['item', 'raw', 'role', 'roles', 'offices', 'lawsuitStatus', 'portfolios', 'provinces'],
    computed: {
      view: function() {
        return this.$route.params.action
      },
      tab: function() {
        return this.$route.params.tab
      },
      editLink: function () {
        const rolesVals = this.roles.map(i => {return i.value})
        if (rolesVals.includes('lawyer')) {
          return '/lawsuit/edit/' + this.$route.params.id
        }
        return '/lawsuit/edit/' + this.$route.params.id
      },
      isInternal: function() {
        const rolesVals = this.roles.map(i => {return i.value})
        if (!rolesVals.includes('lawyer')) {
          return true
        }
        return false
      },
      dictionary: function () {
        return this.$store.getters.dictionary
      }
    },
  }
</script>
