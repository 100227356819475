<template>
    <div>
        <v-row>
            <v-col v-if="mode === 'view'" md=8>
               Status:  <v-chip
                    dark
                    v-if="status"
                    :color="status.color"
                >
                    <h2><v-icon dark small>
                        {{status.icon}}
                    </v-icon>
                    {{status.name}}</h2>
                </v-chip>

                <v-chip
                    class="ma-2"
                    v-if="!status"
                >
                    <h2>{{dictionaryKey}}</h2>
                </v-chip>
            </v-col>
            <v-col v-if="mode === 'edit'" md=7>
                    <v-select
                        id="officeInput"
                        name="status"
                        label="Status"
                        v-model="dictionaryKey"
                        item-text="name"
                        item-value="value"
                        :items="lawsuitStatuses"
                    ></v-select>
            </v-col>

            <v-col v-if="mode === 'view'" md=4>
                <v-btn
                id="editStatusButtonSm"
                fab
                dark
                x-small
                @click="edit()"
                >
                    <v-icon dark small>
                        mdi-pencil
                    </v-icon>
                </v-btn>
            </v-col>

            <v-col v-if="mode === 'edit'" md=5>

                <v-btn
                id="saveStatusButtonSm"
                fab
                dark
                x-small
                @click="save()"
                >
                    <v-icon dark small>
                        mdi-check
                    </v-icon>
                </v-btn>
                
                <v-btn
                id="cancelButtonSm"
                fab
                dark
                x-small
                @click="view()"
                >
                    <v-icon dark small>
                        mdi-cancel
                    </v-icon>
                </v-btn>
                <v-btn
                id="addStatusButtonSm"
                fab
                dark
                x-small
                link :to="'/type/status/add'"
                >
                    <v-icon dark small>
                        mdi-plus
                    </v-icon>
                </v-btn>

            </v-col>

        </v-row>
    </div>
</template>
<script>
    const {EventBus} = require('../../addons/event-bus.js')

    export default {
        name: 'LawsuitStatusDisplay',
        props: ['dictionaryKey'],
        data() {
            return {
                mode: 'view'
            }
        },
        computed: { 
            status: function () {
                return this.$store.getters.dictionary[this.dictionaryKey]
            },
            lawsuitStatuses: function () {
                return this.$store.getters.typingSelectList.filter(i => { return i.scope === 'lawsuit-legal-status'})
            },
            roles: function () {
                return this.$store.getters.profileRoles
            },
        },
        methods: {
            edit() {
                this.mode = 'edit'
            },
            view() {
                this.mode = 'view'
            },
            save() {
                EventBus.$emit('status-change-submitted', {status: this.dictionaryKey, pk: this.$route.params.id})
            }
        }
    }
</script>
