<template>
  <div v-if="items">
    <p>mostrando {{seizedPropertyItems.length}} bens de {{items.length}} casos</p>
    <v-row>
      <v-col md="4">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Filtro"
          single-line
          hide-details
        ></v-text-field>
      </v-col>

      <v-col md="6">&nbsp;</v-col>
      
      <v-col md="2">
      
      </v-col>
    </v-row>
    <br>
    <br>

    <v-data-table
      :headers="headers"
      :items="seizedPropertyItems"
      :search="search"
    >
      <template v-slot:item="row">
          <tr>
            <td><p>{{row.item.received}}</p></td>
            <td><status-table-display :dictionaryKey="row.item.portfolio"></status-table-display></td>
            <td v-html="row.item.case"></td>
            <td><status-table-display :dictionaryKey="row.item.type"></status-table-display></td>
            <td><p>{{row.item.value}}</p></td>
            <td><status-table-display :dictionaryKey="row.item.status"></status-table-display></td>
            <td><p>{{row.item.company_name}}</p></td>
            <td><p>{{row.item.office ? row.item.office.name : ''}}</p></td>
            <td><p>{{row.item.ccb}}</p></td>
            <td><p>{{row.item.cnpj}}</p></td>
            <td>
              <edit-button 
                view="small"
                :path="'/lawsuit/view/' + row.item.lawsuit_id + '/property/edit/' + row.item.refcode"
              ></edit-button>
          
            </td>
          </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import EditButton from '../../components/EditButton.vue'
  import StatusTableDisplay from '../../components/StatusTableDisplay.vue'
  const {displaySeizedPropertyReportTableHeaders, displaySeizedPropertyReportTable} = require('../descriptors.js')

  export default {
    components: {
      EditButton,
      StatusTableDisplay,
    },
    name: 'SeizedPropertyReportTable',
    props: ['items'],
    data() {
      return {
        search: '',
      }
    },
    computed: {
      headers() {
        return displaySeizedPropertyReportTableHeaders()
      },
      seizedPropertyItems() {
        return displaySeizedPropertyReportTable(this.items)
      },
    },
  }

</script>

<style scoped>
td {
  height:  100px !important;
}
</style>